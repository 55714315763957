import React from 'react';

interface Props {
  color: string;
  width: number;
  height: number;
}

const PlayIC: React.FC<Props> = ({ color = '#06396a', width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 36.25L35 25L20 13.75V36.25ZM25 0C11.2 0 0 11.2 0 25C0 38.8 11.2 50 25 50C38.8 50 50 38.8 50 25C50 11.2 38.8 0 25 0ZM25 45C13.975 45 5 36.025 5 25C5 13.975 13.975 5 25 5C36.025 5 45 13.975 45 25C45 36.025 36.025 45 25 45Z"
        fill={color}
      />
    </svg>
  );
};
export default PlayIC;
