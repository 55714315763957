import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideNavBar } from './data/sidebar.model';
import './Sidebar.scss';

type Props = {
    routes?: SideNavBar[];
};

const Sidebar = (props: Props) => {
    const location = useLocation();

    return (
        <div className="sidebar">
            <ul className="sidebar-list">
                {props.routes?.map((navItem: SideNavBar) => (
                    <li key={navItem.index}>
                        <Link
                            className={`${
                                location.pathname === navItem.path
                                    ? 'active'
                                    : ''
                            }`}
                            to={{
                                pathname: navItem.path,
                                state: null,
                            }}
                        >
                            {navItem.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
