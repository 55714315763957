import React from 'react';
import { Field, Form, Formik } from 'formik';
import { ADSTAGS } from '../../../../../../assets';
import * as Yup from 'yup';
import './AdTags.scss';
import { AdTag, createAd } from '../data';
import { showAlert } from '../../../../../../store';

type Props = {
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  updateAdtag?: (data: { name: string; url: string; ref_id: string }) => void;
  ref_id?: string;
  url?: string;
  name?: string;
  selectedAd: AdTag | null;
};

const AdTags: React.FC<Props> = ({ setPopUp, updateAdtag, selectedAd }) => {
  const initialValues = {
    tagname: selectedAd ? selectedAd.name : '',
    tagURI: selectedAd ? selectedAd.url : '',
  };
  const validationSchema = Yup.object().shape({
    tagname: Yup.string().required('This field is required'),
    tagURI: Yup.string()
      .required('This field is required')
      .url('Enter valid url'),
  });

  const submitTagForm = (event: { tagname: string; tagURI: string }) => {
    if (updateAdtag && selectedAd) {
      updateAdtag({
        name: event.tagname,
        url: event.tagURI,
        ref_id: selectedAd.ref_id,
      });
    } else {
      createAd({ name: event.tagname, url: event.tagURI })
        .then((response) => {
          setPopUp(false);
          showAlert({
            alertMessage: 'AD created successfuly.',
            alertType: 'success',
            isAlertVisible: true,
          });
        })
        .catch((err) => {
          showAlert({
            alertMessage: 'Failed to save AD.',
            alertType: 'error',
            isAlertVisible: true,
          });
        });
    }
  };

  return (
    <div className="adtags">
      <div className="left-poster">
        <div className="left-sec">
          <button onClick={() => setPopUp(false)}>Back</button>
          <div className="img-fluid">
            <img src={ADSTAGS} alt="ADSTAGSPoster" />
          </div>
        </div>
      </div>
      <div className="tags-form">
        <h2 className="form-heading">Vast Ad Tags </h2>
        <p className="form-sub-heading">
          Please insert the vast compliant ad tag that you would like to add to
          your content.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitTagForm}
        >
          {({ errors }) => (
            <Form>
              <div className="form-control">
                {errors.tagname && <p>{errors.tagname}</p>}
                <Field
                  name="tagname"
                  type="text"
                  placeholder="Ad Tag Name"
                  className={errors.tagname && 'error'}
                />
              </div>
              <div className="form-control">
                {errors.tagURI && <p>{errors.tagURI}</p>}
                <Field
                  name="tagURI"
                  type="text"
                  placeholder="Ad Tag URl"
                  className={errors.tagURI && 'error'}
                />
              </div>
              <div className="select-content">
                <div className="content-heading">
                  <div>Select Content </div>
                  <div className="btn">Select all</div>
                </div>
              </div>
              <div className="p-relative">
                <div className="p-abs">
                  <p className="top">
                    Please select all content, where you would want this ad tag
                    to be active to display the line items connected to the ad
                    tags
                  </p>
                  <div className="submit-sec">
                    <button type="submit" className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdTags;
