import { Avatar, Button, MenuItem, Modal, styled } from '@mui/material';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LOGO } from '../../../../assets';
import { AssociatedTeams, LOGIN, User } from '../../../../store/data';
import {
  decodeStringUsingATOB,
  encodeObjectUsingBTOA,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../../../utils/utilityService';
import './Header.scss';

const StyledModal = styled(Modal)({
  left: 'unset',
  right: '15px',
  top: '70px',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: 0,
    right: '10px',
    top: '-10px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: `10px solid #fff`,
  },
  '.MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
});

const Header = () => {
  const history = useHistory();
  const userInformation = decodeStringUsingATOB(
    getFromLocalStorage('userInformation')
  ) as User;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<AssociatedTeams | undefined>(
    userInformation.selectedRole
  );

  const handleUserRoleChange = (id: number) => {
    if (id) {
      const selectedRole = userInformation.associated_teams?.filter(
        (item) => item.business.id.toString() === id.toString()
      )[0];
      setToLocalStorage(
        'userInformation',
        encodeObjectUsingBTOA({ ...userInformation, selectedRole })
      );
      setSelectedRole(selectedRole);
      window.location.reload();
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push(LOGIN);
  };

  const toggleProfile = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="dashboard-header">
      <div className="header-logo">
        <Link to="/">
          <img src={LOGO} alt="logo" />
        </Link>
      </div>
      <div className="header-right-sec">
        <div className="header-search form-group"></div>
        <div className="header-profile">
          <Avatar
            alt={
              userInformation.selectedRole?.business.name
                ? userInformation.selectedRole?.business.name
                : 'Guest'
            }
            onClick={toggleProfile}
          />
          <StyledModal open={isOpen} onClose={toggleProfile}>
            <div className="header-profile-popup">
              <div className="header-profile-info">
                <div className="avatar">
                  <Avatar
                    alt={
                      userInformation.selectedRole?.business.name
                        ? userInformation.selectedRole?.business.name
                        : 'Guest'
                    }
                  />
                </div>
                <div className="user-email">{userInformation.email}</div>
              </div>
              <div className="header-profile-accounts">
                {userInformation.associated_teams?.map((item) => (
                  <MenuItem
                    value={item.business.id}
                    key={item.business.id}
                    disabled={!item.business.active}
                    className={`${
                      selectedRole?.business.id === item.business.id
                        ? 'selectedItem'
                        : ''
                    }`}
                    onClick={() => handleUserRoleChange(item.business.id)}
                  >
                    {item.business.name} ({item.role})
                  </MenuItem>
                ))}
              </div>
              <div className="header-profile-logout">
                <Button onClick={handleLogout}>Log out</Button>
              </div>
            </div>
          </StyledModal>
        </div>
      </div>
    </div>
  );
};

export default Header;
