import React from 'react';
import { HAPPY_ANNOUNCEMENT } from '../../../../../assets';

type Props = {
    fileUploadSteps: string;
    setFileUploadSteps: React.Dispatch<React.SetStateAction<string>>;
};

export const FileUploadSuccess = (props: Props) => {
    if (props.fileUploadSteps !== 'upload-success') {
        return null;
    }
    return (
        <div className="file-upload-section">
            <img
                src={HAPPY_ANNOUNCEMENT}
                alt="uploaded successfully"
                className="img-sm"
            />
            <h3>Your content was sucessfully uploaded</h3>
            <button onClick={() => props.setFileUploadSteps('file-select')}>
                upload
            </button>
        </div>
    );
};
