import { CardData } from '.';
import {
  SUBTITLE_IC,
  TRAILER_IC,
  VIDEO_FILE_IC,
  BANNER_IC,
  THUMBNAIL_IC,
  SEASON,
} from '../../../../../assets';
import { MY_VIDEO } from '../../../../../utils/routes.constants';

export const myVideoList: CardData[] = [
  {
    index: 1,
    image: SEASON,
    title: 'Season',
    assetType: 'seasons',
    asset: 'seasons',
    description:
      'Create the desired season by clicking the button on the right.',
    button: 'Create',
    viewSeason: {
      image: SEASON,
      title: 'Season Number : ',
      button: 'View',
    },
  },
  {
    index: 2,
    image: VIDEO_FILE_IC,
    title: 'Video File',
    assetType: 'video',
    asset: 'videos',
    description:
      'Upload your main video file here by clicking the button below.',
    button: 'upload',
  },
  {
    index: 3,
    image: TRAILER_IC,
    title: 'trailer',
    assetType: 'trailer',
    asset: 'trailers',
    description:
      'Upload your movie trailer here, that you would like to display with the video.',
    button: 'upload',
  },
  {
    index: 4,
    image: SUBTITLE_IC,
    title: 'Sub title',
    assetType: 'subtitle',
    asset: 'subtitles',
    description:
      'Upload Subtitle file here, that you would like to play with your video.',
    button: 'upload',
  },
  {
    index: 5,
    image: BANNER_IC,
    title: 'Banner',
    assetType: 'banner',
    asset: 'banners',
    description:
      'Upload Your Video Banner here. please ensure 1920 x 1080 Resolution',
    button: 'upload',
  },
  {
    index: 6,
    image: THUMBNAIL_IC,
    title: 'Thumbnail',
    assetType: 'thumbnail',
    asset: 'thumbnails',
    description:
      'Upload Your Thumbnails here Please ensure 675 x 1000 Resolution.',
    button: 'upload',
  },
  {
    index: 7,
    image: THUMBNAIL_IC,
    title: 'Audio',
    assetType: 'audio',
    asset: 'audios',
    description:
      'Upload Your audio here, that you would like to display with the video.',
    button: 'upload',
  },
];

export const ENABLE_AND_PUBLISHING = 'enable_publishing';
export const MONITIZATION = 'monitization';

export const myVideoRoutes = [
  {
    index: 1,
    title: 'my video',
    to: MY_VIDEO,
  },
  // {
  //     index: 2,
  //     title: 'enable & publishing',
  //     to: ENABLE_AND_PUBLISHING,
  // },
  {
    index: 3,
    title: 'monitization',
    to: MONITIZATION,
  },
];

export const castRole = [
  { name: 'Actor', value: 0 },
  { name: 'Director', value: 1 },
  { name: 'Producer', value: 2 },
  { name: 'Singer', value: 3 },
  { name: 'Musician', value: 4 },
];
