import React from 'react';

interface Props {
  color: string;
  width: number;
  height: number;
}

const PlayCheckIC: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8889 24.5156H5.75403V29.3714H34.8889V24.5156ZM34.8889 14.804H5.75403V19.6598H34.8889V14.804ZM5.75403 39.083H25.1773V34.2272H5.75403V39.083ZM53.0982 28.1574L56.74 31.7993L39.7447 48.7946L28.8191 37.869L32.461 34.2272L39.7447 41.5109L53.0982 28.1574Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayCheckIC;
