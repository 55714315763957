import { AxiosError } from 'axios';
import { handelUnathorized } from '../services/api';
import { showAlert } from '../store';
import {
  BAD_GATEWAY,
  REQUEST_NOT_FOUND,
  SERVER_ERROR_MESSAGE,
  SERVER_REFUSED,
  SOMETHING_WENT_WRONG,
} from './app.constants';

export const handleApiError = (error: AxiosError) => {
  try {
    console.error(`[response error] [${JSON.stringify(error)}]`);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          handleBadRequest(error);
          break;
        case 401:
          handelUnathorized();
          break;
        case 403:
          handleServerRefusedError();
          break;
        case 404:
          handleAPINotFound();
          break;
        case 500:
          handleServerError();
          break;
        case 502:
          handleBadGateWay();
          break;
        default:
          handleUncaughtError();
          break;
      }
    }
  } catch (error) {
    handleUncaughtError(error);
  }
};

const handleServerRefusedError = () => {
  showAlert({
    alertMessage: SERVER_REFUSED,
    alertType: 'error',
    isAlertVisible: true,
  });
};

const handleAPINotFound = () => {
  showAlert({
    alertMessage: REQUEST_NOT_FOUND,
    alertType: 'error',
    isAlertVisible: true,
  });
};

const handleServerError = () => {
  showAlert({
    alertMessage: SERVER_ERROR_MESSAGE,
    alertType: 'error',
    isAlertVisible: true,
  });
};

const handleBadGateWay = () => {
  showAlert({
    alertMessage: BAD_GATEWAY,
    alertType: 'error',
    isAlertVisible: true,
  });
};

export const handleUncaughtError = (error?: any) => {
  console.error('[uncaught error]', error);
  showAlert({
    alertMessage: SOMETHING_WENT_WRONG,
    alertType: 'error',
    isAlertVisible: true,
  });
};

const handleBadRequest = (error: any) => {
  try {
    const errorObject = error.response ? error.response.data : null;
    let errorMessage: string = '';
    if (errorObject) {
      for (const key in errorObject) {
        if (Object.prototype.hasOwnProperty.call(errorObject, key)) {
          errorMessage = `${key}: ${errorObject[key]}`;
        }
      }
    }
    showAlert({
      alertMessage: errorMessage,
      alertType: 'error',
      isAlertVisible: true,
    });
  } catch (error) {
    handleUncaughtError(error);
  }
};

export class Timer {
  private timerId: any;
  private start: any;
  private remaining: any;
  private callback: any;
  constructor(callback: (this: void) => void, delay: number) {
    this.remaining = delay;
    this.callback = callback;
  }

  pause() {
    window.clearTimeout(this.timerId);
    this.remaining -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();
    window.clearTimeout(this.timerId);
    this.timerId = window.setTimeout(this.callback, this.remaining);
  }
}

export const setToLocalStorage = (
  key: 'userInformation' | string,
  data: Record<string, unknown> | string
) => {
  if (typeof data === 'string') {
    window.localStorage.setItem(key, data);
  } else {
    window.localStorage.setItem(key, JSON.stringify(data));
  }
};

export const getFromLocalStorage = (key: 'userInformation' | string) => {
  return window.localStorage.getItem(key);
};

export const encodeObjectUsingBTOA = (
  data: Record<string, unknown>
): string => {
  return window.btoa(JSON.stringify(data));
};

export const decodeStringUsingATOB = (data: string | null): unknown => {
  return data ? JSON.parse(window.atob(data)) : JSON.parse(window.atob(''));
};
