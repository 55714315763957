import React from 'react';
import { UPLOAD_SVG_IC } from '../../../../../assets';

type Props = {
  fileUploadSteps: string;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
};

export const FileSelect = (props: Props) => {
  if (props.fileUploadSteps !== 'file-select') {
    return null;
  }
  return (
    <div className="file-upload-section">
      <img src={UPLOAD_SVG_IC} alt="upload icon" />
      <h3>Click the select button below to begin.</h3>
      <input
        className="upload-input"
        id="file"
        type="file"
        value=""
        onChange={props.handleFileUpload}
      />
      <label htmlFor="file">Select {props.title}</label>
    </div>
  );
};
