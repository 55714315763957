import React from 'react';
import { UPLOAD_FRAME } from '../../../../../assets';
import { trimLongString } from '../utils/utility';

type Props = {
    fileUploadSteps: string;
    uploadToS3Bucket: () => Promise<void>;
    selectedFile: any;
};

export const FileUpload = (props: Props) => {
    if (props.fileUploadSteps !== 'file-upload') {
        return null;
    }

    return (
        <div className="file-upload-section">
            <img src={UPLOAD_FRAME} alt="file preview" />
            <h3 title={props.selectedFile.name}>
                Your “{trimLongString(props.selectedFile.name)}” Ready for
                Upload.
            </h3>
            <button onClick={() => props.uploadToS3Bucket()}>Upload</button>
        </div>
    );
};
