import React, { FunctionComponent } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { InitialState } from '../store/data';
import { LOGIN } from '../utils/routes.constants';

type Props = {
    path: string;
    exact: boolean;
    Component: FunctionComponent<RouteComponentProps>;
};

function PrivateRoute({ path, exact, Component }: Props): React.ReactElement {
    const isAuthenticated: boolean = useSelector(
        (state: InitialState) => state.isAuthenticated,
        shallowEqual
    );

    return (
        <>
            {isAuthenticated ? (
                <Route
                    path={path}
                    exact={exact}
                    render={(props) => <Component {...props} />}
                />
            ) : (
                <Redirect to={LOGIN} />
            )}
        </>
    );
}

export default PrivateRoute;
