import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { addOrUpdateMovie } from '../../../../store';
import { InitialState, UploadMetaForm } from '../../../../store/data';
import { TYPE_OF_CONTENT, UPLOAD } from '../../../../utils/app.constants';
import {
  fetchListCollection,
  fetchMoreContents,
} from '../../data/dashboard.fetch';
import { ContentCard } from '../ContentCard';
import { CollectionDetails, initialValues } from '../../data/dashboard.model';
import MetaDataForm from '../MetaDataForm';
import './Collections.scss';
import PlayIC from '../../../../assets/PlayIC';
import PlaylistIC from '../../../../assets/PlaylistIC';

const Collections = () => {
  const addMovie: UploadMetaForm = useSelector(
    (state: InitialState) => state.addMovie,
    shallowEqual
  );
  const [selectedVideoType, setSelectedVideoType] = useState<
    'movie' | 'playlist'
  >('movie');
  const [listCollection, setListCollection] = useState<CollectionDetails[]>([]);
  const [viewMore, setViewMore] = useState<string | null>(null);
  const toggleForm = React.useCallback((addMovie: UploadMetaForm) => {
    addOrUpdateMovie(addMovie);
  }, []);
  const [isMultipart, setMultipart] = useState<boolean>(false);

  const getListCollection = React.useCallback(async (url: string) => {
    const contents: any = await fetchListCollection(url);
    setListCollection(contents.results);
    setViewMore(contents.next);
  }, []);

  useEffect(() => {
    void getListCollection('list_movies');
  }, [getListCollection]);

  const handleViewMore = async () => {
    if (viewMore) {
      if (selectedVideoType === 'movie') {
        const moreContent: any = await fetchMoreContents(
          viewMore,
          'list_movies'
        );
        setListCollection((prev: any) => {
          return [...prev, ...moreContent.results];
        });
        setViewMore(moreContent.next);
      } else {
        const moreContent: any = await fetchMoreContents(
          viewMore,
          'list_collections'
        );
        setListCollection((prev: any) => {
          return [...prev, ...moreContent.results];
        });
        setViewMore(moreContent.next);
      }
    }
  };

  const handleMovieSelection = () => {
    setSelectedVideoType('movie');
    void getListCollection('list_movies');
  };
  const handlePlayListSelection = () => {
    setSelectedVideoType('playlist');
    void getListCollection('list_collections');
  };

  return (
    <>
      {addMovie.isMetaDataFormPopUp && (
        <MetaDataForm
          initialMetaData={initialValues}
          isMultipart={isMultipart}
        />
      )}
      <div className="main-content">
        <h3 className="main-heading">{TYPE_OF_CONTENT}</h3>
        <div className="main-heading-row">
          <div className="type-of-content">
            <div>
              <input
                type="radio"
                name="video-type"
                value="movie"
                checked={selectedVideoType === 'movie' ? true : false}
                id="movie"
              />
              <label htmlFor="movie" onClick={() => setMultipart(false)}>
                <div
                  className="card-type active"
                  onClick={handleMovieSelection}
                >
                  <PlayIC width={50} height={50} color={'#06396a'} />
                  <span>Videos</span>
                </div>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="video-type"
                checked={selectedVideoType === 'playlist' ? true : false}
                value="playlist"
                id="playlist"
              />
              <label htmlFor="playlist" onClick={() => setMultipart(true)}>
                <div className="card-type" onClick={handlePlayListSelection}>
                  <PlaylistIC width={50} height={50} color={'#06396a'} />
                  <span>Playlists</span>
                </div>
              </label>
            </div>
          </div>
          <button
            className="btn btn-primary btn-sm text-uppercase"
            onClick={() =>
              toggleForm({
                isMetaDataFormPopUp: true,
                isUpdate: false,
              })
            }
          >
            {UPLOAD}
          </button>
        </div>
        <div className="videolist">
          <div className="video-cards">
            {listCollection.length !== 0
              ? listCollection.map((item: CollectionDetails) => {
                  return <ContentCard details={item} key={item.ref_id} />;
                })
              : null}
          </div>
          {viewMore && (
            <div className="load-more">
              <button
                className="btn btn-secondary btn-sm text-uppercase"
                onClick={handleViewMore}
              >
                Load more contents
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Collections;
