import React, { useState } from 'react';
import Header from '../Header';
import { SIGN_UP } from '../../../../utils/app.constants';
import { MAN_WITH_CAMERA } from '../../../../assets';
import { Formik, Form, Field } from 'formik';
import {
    signUpValidators,
    signUpInitialValues,
    SignUpValues,
    signup,
} from '../../data';
import './Register.scss';
import { LOGIN } from '../../../../utils/routes.constants';
import { Link } from 'react-router-dom';

const Register = () => {
    const [isPopUp, setPopUp] = useState<boolean>(true);
    const handleSignup = (values: SignUpValues) => {
        signup(values);
    };
    return (
        <div className="signup">
            {isPopUp && (
                <div className="coming-soon">
                    <Link to={LOGIN} className="back-to-login">
                        go back
                    </Link>
                    {/* <button className="close" onClick={() => setPopUp(false)}></button> */}
                    <h2>Coming Soon</h2>
                </div>
            )}
            <Header />
            <div className="signup-form">
                <div className="signup-form-left-sec">
                    <img
                        src={MAN_WITH_CAMERA}
                        alt="man with camera"
                        loading="lazy"
                    />
                </div>
                <div className="signup-form-right-sec">
                    <div className="form-container">
                        <div className="signup-heading">
                            <h3>{SIGN_UP}</h3>
                        </div>
                        <Formik
                            initialValues={signUpInitialValues}
                            validationSchema={signUpValidators}
                            onSubmit={handleSignup}
                        >
                            {({ errors, touched }) => {
                                return (
                                    <>
                                        <Form>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.fullName &&
                                                        touched.fullName &&
                                                        'error'
                                                    }`}
                                                    name="fullName"
                                                    placeholder="Name"
                                                />
                                                {errors.fullName &&
                                                    touched.fullName && (
                                                        <span className="error-msg">
                                                            {errors.fullName}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.companyName &&
                                                        touched.companyName &&
                                                        'error'
                                                    }`}
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                />
                                                {errors.companyName &&
                                                    touched.companyName && (
                                                        <span className="error-msg">
                                                            {errors.companyName}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.phoneNumber &&
                                                        touched.phoneNumber &&
                                                        'error'
                                                    }`}
                                                    name="phoneNumber"
                                                    placeholder="Phone Number"
                                                    type="number"
                                                />
                                                {errors.phoneNumber &&
                                                    touched.phoneNumber && (
                                                        <span className="error-msg">
                                                            {errors.phoneNumber}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.emailId &&
                                                        touched.emailId &&
                                                        'error'
                                                    }`}
                                                    name="emailId"
                                                    placeholder="Email"
                                                />
                                                {errors.emailId &&
                                                    touched.emailId && (
                                                        <span className="error-msg">
                                                            {errors.emailId}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.password &&
                                                        touched.password &&
                                                        'error'
                                                    }`}
                                                    name="password"
                                                    placeholder="Password"
                                                />
                                                {errors.password &&
                                                    touched.password && (
                                                        <span className="error-msg">
                                                            {errors.password}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="form-group">
                                                <Field
                                                    className={`input-group ${
                                                        errors.domainName &&
                                                        touched.domainName &&
                                                        'error'
                                                    }`}
                                                    name="domainName"
                                                    placeholder="Domain Name"
                                                />
                                                {errors.domainName &&
                                                    touched.domainName && (
                                                        <span className="error-msg">
                                                            {errors.domainName}
                                                        </span>
                                                    )}
                                            </div>
                                            <div className="action-row">
                                                <button
                                                    className="btn btn-primary btn-lg btn-block"
                                                    type="submit"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
