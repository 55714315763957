import {
  CONTENT_MANAGER,
  MONITIZATION,
} from '../../../../../utils/routes.constants';
import { SideNavBar } from './sidebar.model';

export const sidebarList: SideNavBar[] = [
  {
    index: 1,
    title: 'My Video',
    path: CONTENT_MANAGER,
  },
  {
    index: 2,
    title: 'Monitization',
    path: MONITIZATION,
  },
];
