import React from 'react';
import { EpisodeType } from '../../../Dashboard/data';
import './EpisodeViewCard.scss';

type Props = {
  episode: EpisodeType;
  handleEpisodeSelect: (refId: string) => void;
};

const EpisodeViewCard: React.FC<Props> = ({ episode, handleEpisodeSelect }) => {
  return (
    <div className="video-asset-card">
      <div className="asset-image">
        <svg
          width="64"
          height="75"
          viewBox="0 0 64 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.6211 36.2341H22.6032C22.1249 36.2326 21.6631 36.0578 21.3032 35.742L12.6461 28.0898C12.3489 27.8268 11.966 27.6817 11.5694 27.6819H4.09827V1.85671C4.09882 1.36445 4.29419 0.892514 4.6415 0.544433C4.98882 0.196352 5.45972 0.000556354 5.9509 0H41.7684C42.2596 0.000556057 42.7305 0.196352 43.0778 0.544433C43.4252 0.892514 43.6205 1.36445 43.6211 1.85671L43.6211 36.2341Z"
            fill="#E6E6E6"
          />
          <path
            d="M12.6878 11.9842C14.8581 11.9842 16.6176 10.2209 16.6176 8.04576C16.6176 5.8706 14.8581 4.10728 12.6878 4.10728C10.5174 4.10728 8.75793 5.8706 8.75793 8.04576C8.75793 10.2209 10.5174 11.9842 12.6878 11.9842Z"
            fill="white"
          />
          <path
            d="M27.9579 5.93586H20.6597C20.5257 5.93586 20.3972 5.98921 20.3024 6.08417C20.2076 6.17914 20.1544 6.30794 20.1544 6.44224C20.1544 6.57653 20.2076 6.70533 20.3024 6.8003C20.3972 6.89526 20.5257 6.94861 20.6597 6.94861H27.9579C28.0919 6.94861 28.2204 6.89526 28.3152 6.8003C28.41 6.70533 28.4632 6.57653 28.4632 6.44224C28.4632 6.30794 28.41 6.17914 28.3152 6.08417C28.2204 5.98921 28.0919 5.93586 27.9579 5.93586Z"
            fill="white"
          />
          <path
            d="M38.5527 9.14291H20.5631C20.3374 9.14291 20.1544 9.37022 20.1544 9.64929C20.1544 9.92836 20.3374 10.1557 20.5631 10.1557H38.5527C38.7784 10.1557 38.9614 9.92836 38.9614 9.64929C38.9614 9.37022 38.7784 9.14291 38.5527 9.14291Z"
            fill="white"
          />
          <path
            d="M37.1391 15.9227H10.5802C10.3646 15.9227 10.1895 16.15 10.1895 16.4291C10.1895 16.7082 10.3646 16.9355 10.5802 16.9355H37.1391C37.3546 16.9355 37.5298 16.7082 37.5298 16.4291C37.5298 16.15 37.3546 15.9227 37.1391 15.9227Z"
            fill="white"
          />
          <path
            d="M37.1391 19.0735H10.5802C10.3646 19.0735 10.1895 19.3008 10.1895 19.5799C10.1895 19.859 10.3646 20.0863 10.5802 20.0863H37.1391C37.3546 20.0863 37.5298 19.859 37.5298 19.5799C37.5298 19.3008 37.3546 19.0735 37.1391 19.0735Z"
            fill="white"
          />
          <path
            d="M37.1391 22.2243H10.5802C10.3646 22.2243 10.1895 22.4516 10.1895 22.7307C10.1895 23.0098 10.3646 23.2371 10.5802 23.2371H37.1391C37.3546 23.2371 37.5298 23.0098 37.5298 22.7307C37.5298 22.4516 37.3546 22.2243 37.1391 22.2243Z"
            fill="white"
          />
          <path
            d="M37.1391 25.3751H10.5802C10.3646 25.3751 10.1895 25.6024 10.1895 25.8815C10.1895 26.1605 10.3646 26.3878 10.5802 26.3878H37.1391C37.3546 26.3878 37.5298 26.1605 37.5298 25.8815C37.5298 25.6024 37.3546 25.3751 37.1391 25.3751Z"
            fill="white"
          />
          <path
            d="M61.0245 36.2341H22.6032C22.2207 36.2347 21.8465 36.1227 21.527 35.9119L21.5017 35.8953V9.62116C21.5023 9.1289 21.6976 8.65696 22.0449 8.30888C22.3923 7.96079 22.8632 7.765 23.3543 7.76444H59.1719C59.6631 7.765 60.134 7.96079 60.4813 8.30888C60.8286 8.65696 61.024 9.1289 61.0245 9.62116L61.0245 36.2341Z"
            fill="#5CDB94"
          />
          <path
            d="M30.0912 19.7487C32.2616 19.7487 34.021 17.9854 34.021 15.8102C34.021 13.635 32.2616 11.8717 30.0912 11.8717C27.9208 11.8717 26.1614 13.635 26.1614 15.8102C26.1614 17.9854 27.9208 19.7487 30.0912 19.7487Z"
            fill="white"
          />
          <path
            d="M45.3614 13.7003H38.0631C37.9291 13.7003 37.8006 13.7537 37.7058 13.8486C37.6111 13.9436 37.5579 14.0724 37.5579 14.2067C37.5579 14.341 37.6111 14.4698 37.7058 14.5647C37.8006 14.6597 37.9291 14.7131 38.0631 14.7131H45.3614C45.4954 14.7131 45.6239 14.6597 45.7187 14.5647C45.8134 14.4698 45.8666 14.341 45.8666 14.2067C45.8666 14.0724 45.8134 13.9436 45.7187 13.8486C45.6239 13.7537 45.4954 13.7003 45.3614 13.7003Z"
            fill="white"
          />
          <path
            d="M55.9562 16.9073H37.9666C37.7409 16.9073 37.5579 17.1347 37.5579 17.4137C37.5579 17.6928 37.7409 17.9201 37.9666 17.9201H55.9562C56.1819 17.9201 56.3649 17.6928 56.3649 17.4137C56.3649 17.1347 56.1819 16.9073 55.9562 16.9073Z"
            fill="white"
          />
          <path
            d="M54.5426 23.6872H27.9838C27.7682 23.6872 27.593 23.9145 27.593 24.1935C27.593 24.4726 27.7682 24.6999 27.9838 24.6999H54.5426C54.7582 24.6999 54.9334 24.4726 54.9334 24.1935C54.9334 23.9145 54.7582 23.6872 54.5426 23.6872Z"
            fill="white"
          />
          <path
            d="M54.5426 26.838H27.9838C27.7682 26.838 27.593 27.0653 27.593 27.3443C27.593 27.6234 27.7682 27.8507 27.9838 27.8507H54.5426C54.7582 27.8507 54.9334 27.6234 54.9334 27.3443C54.9334 27.0653 54.7582 26.838 54.5426 26.838Z"
            fill="white"
          />
          <path
            d="M54.5426 29.9887H27.9838C27.7682 29.9887 27.593 30.2161 27.593 30.4951C27.593 30.7742 27.7682 31.0015 27.9838 31.0015H54.5426C54.7582 31.0015 54.9334 30.7742 54.9334 30.4951C54.9334 30.2161 54.7582 29.9887 54.5426 29.9887Z"
            fill="white"
          />
          <path
            d="M54.5426 33.1395H27.9838C27.7682 33.1395 27.593 33.3668 27.593 33.6459C27.593 33.925 27.7682 34.1523 27.9838 34.1523H54.5426C54.7582 34.1523 54.9334 33.925 54.9334 33.6459C54.9334 33.3668 54.7582 33.1395 54.5426 33.1395Z"
            fill="white"
          />
          <path
            d="M62.0912 35.9527H22.6032C22.2237 35.9532 21.8552 35.8247 21.5579 35.5881C21.5343 35.5701 21.5118 35.551 21.4894 35.5308L18.1468 32.5769L12.8315 27.8788C12.483 27.5705 12.0342 27.4004 11.5694 27.4006H1.90877C1.40275 27.4013 0.917664 27.6031 0.559855 27.9617C0.202046 28.3203 0.00071613 28.8064 0 29.3136V73.087C0.00071613 73.5942 0.202046 74.0803 0.559855 74.4389C0.917664 74.7975 1.40275 74.9993 1.90877 75H62.0912C62.5972 74.9993 63.0823 74.7975 63.4401 74.4389C63.7979 74.0803 63.9993 73.5942 64 73.087V37.8657C63.9993 37.3586 63.7979 36.8724 63.4401 36.5138C63.0823 36.1552 62.5972 35.9535 62.0912 35.9527V35.9527ZM63.7754 73.087C63.7749 73.5345 63.5973 73.9635 63.2815 74.28C62.9658 74.5964 62.5377 74.7744 62.0912 74.7749H1.90877C1.46226 74.7744 1.03419 74.5964 0.718455 74.28C0.402722 73.9635 0.225105 73.5345 0.224561 73.087V29.3136C0.225105 28.8661 0.402722 28.4371 0.718455 28.1206C1.03419 27.8042 1.46226 27.6262 1.90877 27.6257H11.5694C11.9796 27.6253 12.3758 27.7754 12.6832 28.0476L17.8077 32.5769L21.3401 35.6996C21.6897 36.0066 22.1384 36.1764 22.6032 36.1778H62.0912C62.5377 36.1783 62.9658 36.3563 63.2815 36.6728C63.5973 36.9892 63.7749 37.4182 63.7754 37.8657V73.087Z"
            fill="#3F3D56"
          />
          <path
            d="M49.4035 67.2356H16.393C15.9464 67.2351 15.5183 67.0571 15.2026 66.7406C14.8869 66.4242 14.7092 65.9951 14.7087 65.5476V47.3181C14.7092 46.8706 14.8869 46.4416 15.2026 46.1251C15.5183 45.8087 15.9464 45.6307 16.393 45.6302H49.4035C49.85 45.6307 50.2781 45.8087 50.5938 46.1251C50.9096 46.4416 51.0872 46.8706 51.0877 47.3181V65.5476C51.0872 65.9951 50.9096 66.4242 50.5938 66.7406C50.2781 67.0571 49.85 67.2351 49.4035 67.2356ZM16.393 45.8552C16.006 45.8557 15.635 46.0099 15.3613 46.2842C15.0877 46.5584 14.9337 46.9303 14.9333 47.3181V65.5476C14.9337 65.9355 15.0877 66.3073 15.3613 66.5816C15.635 66.8558 16.006 67.0101 16.393 67.0105H49.4035C49.7905 67.0101 50.1615 66.8558 50.4351 66.5816C50.7088 66.3073 50.8627 65.9355 50.8631 65.5476V47.3181C50.8627 46.9303 50.7088 46.5584 50.4351 46.2842C50.1615 46.0099 49.7905 45.8557 49.4035 45.8552H16.393Z"
            fill="#E6E6E6"
          />
          <path
            d="M21.7263 56.3203C23.8967 56.3203 25.6562 54.557 25.6562 52.3818C25.6562 50.2067 23.8967 48.4434 21.7263 48.4434C19.5559 48.4434 17.7965 50.2067 17.7965 52.3818C17.7965 54.557 19.5559 56.3203 21.7263 56.3203Z"
            fill="#E6E6E6"
          />
          <path
            d="M36.9965 50.2719H29.6983C29.5643 50.2719 29.4357 50.3253 29.341 50.4203C29.2462 50.5152 29.193 50.644 29.193 50.7783C29.193 50.9126 29.2462 51.0414 29.341 51.1364C29.4357 51.2313 29.5643 51.2847 29.6983 51.2847H36.9965C37.1305 51.2847 37.259 51.2313 37.3538 51.1364C37.4485 51.0414 37.5018 50.9126 37.5018 50.7783C37.5018 50.644 37.4485 50.5152 37.3538 50.4203C37.259 50.3253 37.1305 50.2719 36.9965 50.2719Z"
            fill="#E6E6E6"
          />
          <path
            d="M47.5913 53.479H29.6017C29.376 53.479 29.193 53.7063 29.193 53.9854C29.193 54.2644 29.376 54.4917 29.6017 54.4917H47.5913C47.817 54.4917 48 54.2644 48 53.9854C48 53.7063 47.817 53.479 47.5913 53.479Z"
            fill="#E6E6E6"
          />
          <path
            d="M46.1776 60.2588H19.6188C19.4032 60.2588 19.228 60.4861 19.228 60.7652C19.228 61.0443 19.4032 61.2716 19.6188 61.2716H46.1776C46.3932 61.2716 46.5684 61.0443 46.5684 60.7652C46.5684 60.4861 46.3932 60.2588 46.1776 60.2588Z"
            fill="#E6E6E6"
          />
          <path
            d="M46.1776 63.4096H19.6188C19.4032 63.4096 19.228 63.6369 19.228 63.916C19.228 64.195 19.4032 64.4224 19.6188 64.4224H46.1776C46.3932 64.4224 46.5684 64.195 46.5684 63.916C46.5684 63.6369 46.3932 63.4096 46.1776 63.4096Z"
            fill="#E6E6E6"
          />
        </svg>
      </div>
      <div className="asset-info">
        <h3 className="asset-heading">{episode?.title}</h3>
        <p className="asset-description">{episode?.description}</p>
      </div>
      <div className="asset-action">
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => handleEpisodeSelect(episode.ref_id)}
        >
          View
        </button>
      </div>
    </div>
  );
};

export default EpisodeViewCard;
