import React from 'react';
import { ToggleSideBar } from '../data';
import '../../Sidebar/Sidebar.scss';
import { useHistory } from 'react-router';

type Props = {
  toggle: ToggleSideBar[];
  activeTab: string;
  setActivetab: React.Dispatch<React.SetStateAction<string>>;
};

export const TabBar = (props: Props) => {
  const history = useHistory();
  const handleTabClick = (routeTo: string) => {
    props.setActivetab(routeTo);
    history.push(routeTo);
  };
  return (
    <div className="sidebar">
      <ul className="sidebar-list">
        {props.toggle.map((item: ToggleSideBar) => (
          <li key={item.index}>
            <div
              onClick={() => handleTabClick(item.to)}
              className={`tab-item ${
                props.activeTab === item.to ? 'active' : ''
              }`}
            >
              {item.title}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
