import React, { useState } from 'react';
import { Switch } from '@mui/material';
import { Assets } from '../../../../data';
import { CardData } from '../../data';
import { updateActiveAsset } from './data';
import RenderAssetFile from './RenderAssetFile';

type Props = {
  asset: Assets;
  cardData?: CardData;
};

const Preview = ({ cardData, asset }: Props) => {
  const [assetLiveStatus, setLiveStatus] = useState(asset.live);
  const handleLiveStatus = (currentAsset: Assets) => {
    const data = {
      name: currentAsset.name,
      id: currentAsset.id,
    };
    updateActiveAsset(data, setLiveStatus);
  };

  return (
    <div className="asset-preview">
      <RenderAssetFile url={asset.path} fileType={cardData?.asset} />
      <h3>{`${asset.name}-${asset.version}`}</h3>
      <div className="asset-status">
        <Switch
          checked={assetLiveStatus}
          onChange={() => handleLiveStatus(asset)}
        />
      </div>
    </div>
  );
};

export default Preview;
