import React from 'react';
import { UploadPercentage } from './UploadPercentage';
type Props = {
    previewUrl: string;
    selectedFile: any;
    uploadToS3Bucket: () => Promise<void>;
    uploadingPercentage: number;
    isUploading: boolean;
};
export const UploadToS3Bucket = (props: Props) => {
    return (
        <div className="upload-on-bucket">
            <div className="upload-preview mt16">
                {props.selectedFile &&
                props.selectedFile.type.includes('image') ? (
                    <img
                        src={props.previewUrl}
                        alt="Preview"
                        style={{ width: '100px' }}
                        loading="lazy"
                    />
                ) : (
                    <a href={props.previewUrl} target="_blank" rel="noreferrer">
                        {props.selectedFile.name}
                    </a>
                )}
                {!props.selectedFile && (
                    <h3>
                        No files selected, please choose file and click on below
                        upload button
                    </h3>
                )}
                {props.isUploading && (
                    <UploadPercentage
                        uploadingPercentage={props.uploadingPercentage}
                    />
                )}
            </div>
            <button
                className="btn btn-primary mt16"
                onClick={props.uploadToS3Bucket}
                disabled={!props.selectedFile || props.isUploading}
                title={
                    !props.selectedFile
                        ? 'No files selected'
                        : props.isUploading
                        ? 'Uploading'
                        : ''
                }
            >
                {props.isUploading ? 'uploading...' : 'upload profile picture'}
            </button>
        </div>
    );
};
