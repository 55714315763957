import axios, { AxiosResponse } from 'axios';
import { AddCastPayload, PostFormData } from '.';
import { Api } from '../../../../../services';
import { showAlert } from '../../../../../store';
import {
  ARTIST_ADDED,
  CAST_MEMEBER_ADDED,
  FAILED_TO_UPLOAD,
  UPLOAD_SUCCESSFUL,
  UPLOAD_URL_CREATED,
} from '../../../../../utils/app.constants';
import { handleApiError } from '../../../../../utils/utilityService';

export const getUploadUrl = (data: any) => {
  const respinse = Api.post('get_upload_url/', data);
  return respinse;
};

export const getProfileUploadUrl = (
  data: any,
  setPresignedUrl: React.Dispatch<
    React.SetStateAction<PostFormData | undefined>
  >,
  setUploadAssetStatus: React.Dispatch<any>
) => {
  Api.post('get_upload_url/', data).then((response: any) => {
    const data: PostFormData = response.data.url;
    if (response.data && response.data.url) {
      if (response.status === 201 || response.status === 200) {
        setPresignedUrl(data);
        showAlert({
          alertMessage: UPLOAD_URL_CREATED,
          alertType: 'success',
          isAlertVisible: true,
        });
        setUploadAssetStatus(response.data);
      } else {
        showAlert({
          alertMessage: FAILED_TO_UPLOAD,
          alertType: 'success',
          isAlertVisible: true,
        });
      }
    }
  });
};

export const assetUploadStatus = (data: unknown) => {
  Api.post('asset_upload_status/', data).then((response: unknown) => {
    // console.log(response);
  });
};

export const uploadFileToBucket = (
  preSignedUrl: string,
  formData: FormData,
  setUploadingPercentage: React.Dispatch<React.SetStateAction<number>>
) => {
  const response = axios.post(preSignedUrl, formData, {
    headers: { 'Content-Type': 'multipart/form' },
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setUploadingPercentage(percentCompleted);
    },
  });
  return response;
};

export const fetchArtists = (
  artists: string,
  setArtists: React.Dispatch<React.SetStateAction<[]>>
) => {
  Api.get(`list_artists?query=${artists}`).then(
    (respose: AxiosResponse<unknown, any>) => {
      if (respose.status === 200 && respose.data) {
        const data: any = respose.data;
        setArtists(data.data.results);
      }
    }
  );
};

export const addCastInMovie = (data: AddCastPayload) => {
  return Api.post('add_cast_member/', data);
};

export const addArtistMember = (
  values: any,
  collectionDetails: any,
  setNewArtist: React.Dispatch<any>,
  setUploadProfilePic: React.Dispatch<React.SetStateAction<boolean>>
) => {
  Api.post('add_artist/', values).then((response: any) => {
    if (response.status === 201 || response.status === 200) {
      setNewArtist(response.data);
      setUploadProfilePic((prev) => !prev);
      const data: AddCastPayload = {
        name: response.data.name,
        artist_ref_id: response.data.ref_id,
        collection_ref_id: collectionDetails.ref_id,
        role: response.data.role ? response.data.role : 0,
      };
      if (collectionDetails.is_multipart) {
        data.season = collectionDetails.season_ref_id;
      }
      addCastInMovie(data);
    }
  });
};
