import React from 'react';
import { LOGO } from '../../../../assets';
import { GO_BACK } from '../../../../utils/app.constants';
import './Header.scss';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../../utils/routes.constants';

const Header = () => {
    return (
        <div className="header">
            <Link to="/">
                <img src={LOGO} alt="Logo" loading="lazy" />
            </Link>
            <Link to={LOGIN}>{GO_BACK}</Link>
        </div>
    );
};

export default Header;
