import React, { useCallback, useEffect, useState } from 'react';
import VideoAssets from './VideoAssets';
import '../VideoManager.scss';
import './MyVideo.scss';
import { CardData, CastMember, myVideoList } from '../data';
import { FormDataType } from '../../../data/dashboard.model';
import MetaDataForm from '../../MetaDataForm';
import { THUMBNAIL_IC } from '../../../../../assets';
import { Cast } from '../AddCasts/Cast';
import { getCollectionData } from '../../../data';
import { handleUncaughtError } from '../../../../../utils/utilityService';

type Props = {
  refId: string;
};

const MyVideo = ({ refId }: Props) => {
  const [addedCastMember, setAddedCastMember] = useState<Array<CastMember>>([]);
  const [initialData, setInitiallData] = useState<{
    cast_members: Array<CastMember>;
    collection: FormDataType;
    directors: Array<string>;
  }>();

  const getListCollection = useCallback(() => {
    if (refId)
      getCollectionData(refId)
        .then((response: any) => {
          setInitiallData(response.data.data);
        })
        .catch((error) => {
          handleUncaughtError(error);
        });
  }, [addedCastMember]);

  useEffect(() => {
    void getListCollection();
  }, []);

  return (
    <>
      {initialData && (
        <div className="main-content">
          <div className="main-heading-row">
            <h3 className="main-heading">Video assets</h3>
          </div>
          <div className="video-assets">
            <div className="assets-left-section">
              <MetaDataForm
                initialMetaData={{
                  ...initialData.collection,
                  release_date:
                    initialData.collection.release_date.split('T')[0],
                }}
                isMultipart={initialData?.collection.is_multipart}
                isUpdate={true}
              />
              {initialData.cast_members.length !== 0 && <h3>Cast and Crew</h3>}
              <div className="cast-and-crew">
                <div
                  className={`cast-list ${
                    initialData.cast_members.length > 0 ? 'grid-template' : ''
                  }`}
                >
                  {initialData.cast_members.length !== 0 ? (
                    initialData.cast_members.map((castMembers: CastMember) => (
                      <Cast
                        key={castMembers.ref_id}
                        artist={castMembers.artist}
                        addedCastMember={initialData.cast_members}
                        isAdded={true}
                      />
                    ))
                  ) : (
                    <h3>
                      No Artist found, click on below Add Cast button to add
                      your cast members.
                    </h3>
                  )}
                </div>
                <VideoAssets
                  cardData={{
                    index: 7,
                    image: THUMBNAIL_IC,
                    title: 'Movie Cast',
                    assetType: 'movieCast',
                    description: 'Add Your cast here.',
                    button: 'Add',
                  }}
                  videoData={initialData}
                  setAddedCastMember={setAddedCastMember}
                  getListCollection={getListCollection}
                />
              </div>
            </div>
            <div className="asset-cards">
              <VideoAssets
                videoData={initialData}
                setAddedCastMember={setAddedCastMember}
                getListCollection={getListCollection}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyVideo;
