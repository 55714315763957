import { setupInterceptorsTo } from './interceptors';
import axios from 'axios';
import { logOutUser } from '../store';

export const getLocalAccessToken = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    return accessToken;
};

export const deleteAccessToken = () => {
    window.localStorage.clear();
};

export const setLocalAccessToken = (token: string) => {
    window.localStorage.setItem('accessToken', token);
};

export const handelUnathorized = () => {
    deleteAccessToken();
    logOutUser();
};

const Api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    })
);

export default Api;
