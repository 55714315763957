import React, { useEffect } from 'react';
import { showAlert, store } from '../../store';
import { themeTypes } from './data';
import { Timer } from '../../utils/utilityService';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import './Alert.scss';

const Alert = () => {
    const { isAlertVisible, alertMessage, alertType } = store.getState();

    const closeAlert = () => {
        showAlert({ alertMessage: '', isAlertVisible: false });
    };
    const timer = new Timer(function () {
        showAlert({ alertMessage: '', isAlertVisible: false });
    }, 10000);

    useEffect(() => {
        setTimeout(() => {
            showAlert({ alertMessage: '', isAlertVisible: false });
        }, 5000);
    }, [alertMessage]);

    return (
        <>
            {isAlertVisible && (
                <div
                    className={`cms-alert ${themeTypes[alertType]}`}
                    onMouseEnter={() => timer.pause()}
                    onMouseLeave={() => timer.resume()}
                >
                    {alertType === 'success' ? (
                        <DoneIcon />
                    ) : (
                        <ErrorIcon titleAccess={'Error message'} />
                    )}
                    <p className="alert-message">{alertMessage}</p>
                    <CloseIcon className="close-alert" onClick={closeAlert} />
                </div>
            )}
        </>
    );
};

export default Alert;
