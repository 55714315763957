import React from 'react';
import Advertisements from '../Advertisements';
type Props = {
  selectedType: string;
};
const TabContent: React.FC<Props> = ({ selectedType }) => {
  switch (selectedType) {
    case 'subscription':
      return (
        <h3 style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '1rem' }}>
          Coming soon
        </h3>
      );
    case 'payPerView':
      return (
        <h3 style={{ fontSize: '1rem', fontWeight: 'bold', marginTop: '1rem' }}>
          Coming soon
        </h3>
      );
    case 'advertisements':
      return <Advertisements />;
    default:
      return null;
  }
};
export default TabContent;
