import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { Artist, CastMember } from '../data';
import './AddCasts.scss';
import { THUMBNAIL_NOT_AVAILABLE } from '../../../../../assets';

type Props = {
  artist: Artist;
  addArtist?: (artist: Artist) => void;
  addedCastMember: CastMember[];
  isAdded?: boolean;
};

export const Cast = ({
  artist,
  addArtist,
  addedCastMember,
  isAdded,
}: Props) => {
  const isCastMemberAdded = (ref_id: string) => {
    if (addedCastMember && addedCastMember.length)
      return addedCastMember.some((cast) => cast.artist.ref_id === ref_id);
  };

  return (
    <div
      key={artist.ref_id}
      {...(isCastMemberAdded(artist.ref_id) || isAdded
        ? {
            title: 'Cast is already added',
            className: 'artist disabled',
          }
        : {
            className: `artist ${isAdded ? 'disabled' : ''}`,
            onClick: () => addArtist && addArtist(artist),
          })}
    >
      {artist.avatar ? (
        <img
          src={artist.avatar}
          alt="Avatar"
          loading="lazy"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = THUMBNAIL_NOT_AVAILABLE;
          }}
        />
      ) : (
        <div className="no-profile-pic">
          <span>{artist.name[0]}</span>
        </div>
      )}

      <p>{artist.name}</p>
      {isCastMemberAdded(artist.ref_id) || isAdded ? (
        <>
          <CheckIcon>Added</CheckIcon>
        </>
      ) : (
        <AddIcon>add</AddIcon>
      )}
    </div>
  );
};
