import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import Seasons from '../../../../../Seasons';
import { StyledSelect } from '../../../../../Seasons/Seasons';
import { SeasonsType } from '../../../../data';
import { CardData } from '../../data';
import './UploadAsset.scss';
import UploadAssetCard from './UploadAssetCard';

type Props = {
  cardData?: CardData;
  setUploadPopup: (value: React.SetStateAction<boolean>) => void;
  setSeasonPopUp: (value: React.SetStateAction<boolean>) => void;
  collectionRefId?: string;
  seasons?: SeasonsType[];
  getListCollection: () => void;
  language: string;
};

const UploadAsset = ({
  cardData,
  setUploadPopup,
  setSeasonPopUp,
  collectionRefId,
  seasons,
  getListCollection,
  language,
}: Props) => {
  const [isSeasonOpen, setSeasonOpen] = React.useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = React.useState<SeasonsType>();
  const handleSeasonPopUp = React.useCallback(() => {
    if (cardData?.asset === 'seasons') {
      setSeasonPopUp(true);
    } else {
      setUploadPopup(true);
    }
  }, [cardData?.asset]);

  const handleEpisodeSelect = React.useCallback(
    (refId: string) => {
      if (seasons) {
        setSelectedSeason(
          seasons?.filter((season) => season.ref_id === refId)[0]
        );
        setSeasonOpen(true);
      }
    },
    [seasons]
  );

  return (
    <>
      <UploadAssetCard handlePopUp={handleSeasonPopUp} cardData={cardData} />
      {cardData?.asset === 'seasons' && (
        <>
          {seasons &&
            seasons.map((season) => (
              <React.Fragment key={season.ref_id}>
                <div className="video-asset-card">
                  <div className="asset-image">
                    {cardData && (
                      <img
                        src={cardData?.image}
                        alt={cardData?.viewSeason?.title}
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="asset-info">
                    {cardData && (
                      <>
                        <h3 className="asset-heading">{season?.title}</h3>
                        <p className="asset-description">
                          {season?.description}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="asset-action">
                    {cardData && (
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => handleEpisodeSelect(season.ref_id)}
                      >
                        {cardData?.viewSeason?.button}
                      </button>
                    )}
                  </div>
                </div>
                {isSeasonOpen && (
                  <Seasons
                    collectionRefId={collectionRefId}
                    setSeasonOpen={setSeasonOpen}
                    seasonDetails={selectedSeason}
                    getListCollection={getListCollection}
                    language={language}
                  >
                    <StyledSelect>
                      <Select
                        value={selectedSeason?.ref_id}
                        label="Season"
                        renderValue={() =>
                          `Season - ${selectedSeason?.order_by}`
                        }
                        onChange={(event) =>
                          handleEpisodeSelect(event?.target.value)
                        }
                      >
                        {seasons?.map((season) => (
                          <MenuItem
                            disableRipple
                            key={season.ref_id}
                            value={season.ref_id}
                          >{`Season - ${season.order_by}`}</MenuItem>
                        ))}
                      </Select>
                    </StyledSelect>
                  </Seasons>
                )}
              </React.Fragment>
            ))}
        </>
      )}
    </>
  );
};

export default UploadAsset;
