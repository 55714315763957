import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ErrorBoundary from '../../../ErrorBoundary';
import { Header } from '../Header';
import { TabBar } from './TabBar/TabBar';
import { myVideoRoutes } from './data';
import MyVideo from './MyVideo';
import './VideoManager.scss';
import './MyVideo/MyVideo.scss';
import { MY_VIDEO } from '../../../../utils/routes.constants';

const VideoManager = (props: any) => {
  const [activeTab, setActivetab] = useState<string>(MY_VIDEO);
  const refId = props.location.state;
  const history = useHistory();
  if (!refId) {
    history.push('/');
  }

  return (
    <>
      <div className="dashboard video-manager">
        <ErrorBoundary>
          <Header />
        </ErrorBoundary>
        <div className="dashboard-main">
          <ErrorBoundary>
            <TabBar
              toggle={myVideoRoutes}
              activeTab={activeTab}
              setActivetab={setActivetab}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <MyVideo refId={refId} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default VideoManager;
