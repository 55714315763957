import { Api } from '../../../../../../../services';
import { showAlert } from '../../../../../../../store';

export const updateActiveAsset = (
    data: { name: string; id: number },
    setLiveStatus: React.Dispatch<React.SetStateAction<boolean>>
) => {
    Api.post('asset_live_status/', data).then((response) => {
        if (response.status === 201 || response.status === 200) {
            setLiveStatus((prev) => !prev);
            showAlert({
                alertMessage: 'Live status updated.',
                alertType: 'success',
                isAlertVisible: true,
            });
        } else {
            showAlert({
                alertMessage: 'Failed update status',
                alertType: 'error',
                isAlertVisible: true,
            });
        }
    });
};
