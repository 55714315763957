import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { toggleLoader } from '../store';
import { User } from '../store/data';
import {
  decodeStringUsingATOB,
  getFromLocalStorage,
  handleApiError,
} from '../utils/utilityService';
import { getLocalAccessToken } from './api';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  toggleLoader();
  const userInformation = decodeStringUsingATOB(
    getFromLocalStorage('userInformation')
  ) as User;
  if (config.headers && getLocalAccessToken()) {
    if (userInformation.selectedRole) {
      config.headers['Business-id'] =
        userInformation.selectedRole?.business.id.toString();
    }
    config.headers.Authorization = `JWT ${getLocalAccessToken()}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  toggleLoader();
  handleApiError(error);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  toggleLoader();
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  toggleLoader();
  handleApiError(error);
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

// fetch('https://demo-backend.flowgiga.com/dashboard/list_movies',{method: 'GET',
// mode: 'cors',
// cache: 'no-cache',
// credentials: 'same-origin',
// headers: {
//   'Content-Type': 'application/json',
// 'Business-Id':'1',
// Authorization: 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFzaHV0b3NoQGZsb3dnaWdhLmNvbSIsImV4cCI6MTY0NDIyNDQ2MSwiZW1haWwiOiJhc2h1dG9zaEBmbG93Z2lnYS5jb20iLCJvcmlnX2lhdCI6MTY0NDA1MTY2MX0.LaIbJLBKda7hpvhgDNr2bscQCwrA4tztYEqNCLLqWlk'
// },
// redirect: 'follow',
// referrerPolicy: 'no-referrer'})
