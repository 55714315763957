export const APP_NAME = '';
export const GO_BACK = 'Go Back';
export const COPYRIGHT = 'All Rights reserved @flowgiga 2021';
export const SIGN_IN = 'Sign In';
export const SIGN_UP = 'Sign Up';
export const TYPE_OF_CONTENT = 'Type of content';
export const UPLOAD = 'upload';
export const UPLOAD_VIDEO = 'upload video';
export const UPLOAD_HERE = 'please upload your video here';
export const CONTACT_SUPPORT_TEAM = 'please contact support team';
export const LOGIN_SUCCESS = 'Login successful';
export const UPLOAD_URL_CREATED = 'URL created successfully';
export const FAILED_TO_CREATE_URL = 'Failed to create URL';
export const UPLOAD_SUCCESSFUL = 'Uploaded successfully';
export const FAILED_TO_UPLOAD = 'Failed to upload';
export const CAST_MEMEBER_ADDED = 'Cast memeber added successfully.';
export const ARTIST_ADDED = 'Artist added successfully.';
export const SUBMITTED_SUCCESSFULLY = 'Submitted successfully';
export const UPDATED_SUCCESSFULLY = 'Updated successfully';
export const UPLOAD_REQUEST =
  '*we request you to upload a file less than 200 mb as this will only serve as a demo.';
export const SOMETHING_WENT_WRONG =
  'Something went wront, ' + CONTACT_SUPPORT_TEAM + '.';
export const SERVER_REFUSED =
  'Server refused to connect, ' + CONTACT_SUPPORT_TEAM + '.';
export const REQUEST_NOT_FOUND =
  "Resource not found, you don't have access or contact support team.";
export const SERVER_ERROR_MESSAGE =
  'Something went wrong on server, ' + CONTACT_SUPPORT_TEAM + '.';
export const BAD_GATEWAY = 'Bad Gateway, ' + CONTACT_SUPPORT_TEAM + '.';
