import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { Login, Register } from '../components/Authentication';
import ErrorBoundary from '../components/ErrorBoundary';
import PageNotFound from '../components/PageNotFound';
import {
  CONTENT_MANAGER,
  LOGIN,
  MY_VIDEO,
  REGISTER,
  MONITIZATION,
} from '../utils/routes.constants';
import PrivateRoute from './PrivateRoute';
import { ContentManager, VideoUpload } from '../components/Dashboard';
import { VideoManager } from '../components/Dashboard/components/VideoManager';
import { store } from '../store';
import { handelUnathorized } from '../services/api';

const MainNavigation = () => {
  const history = useHistory();
  const { isAuthenticated } = store.getState();
  useEffect(() => {
    const handleExpiredToken = () => {
      if (!isAuthenticated && history && LOGIN) {
        handelUnathorized();
        history.push(LOGIN);
      }
    };
    handleExpiredToken();
  }, [isAuthenticated]);

  return (
    <Router>
      <Switch>
        <Route path={LOGIN}>
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        </Route>
        <Route exact path={REGISTER}>
          <ErrorBoundary>
            <Register />
          </ErrorBoundary>
        </Route>
        <PrivateRoute
          path={MONITIZATION}
          exact={true}
          Component={VideoUpload}
        />
        <PrivateRoute
          path={CONTENT_MANAGER}
          exact={true}
          Component={ContentManager}
        />
        <PrivateRoute path={MY_VIDEO} exact={true} Component={VideoManager} />
        <Route path="*">
          <ErrorBoundary>
            <PageNotFound />
          </ErrorBoundary>
        </Route>
      </Switch>
    </Router>
  );
};

export default MainNavigation;
