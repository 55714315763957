import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import React from 'react';
import { EPISODES } from '../../assets';
import SeasonPopUp from '../Dashboard/components/VideoManager/MyVideo/SeasonPopUp';
import UploadAsset from '../Dashboard/components/VideoManager/MyVideo/UploadAsset';
import { EpisodeType, SeasonsType } from '../Dashboard/data';
import Episodes from './Episodes';
import CreateEpisode from './Episodes/CreateEpisode';
import EpisodeViewCard from './Episodes/EpisodeViewCard';
import './Seasons.scss';

interface Props {
  collectionRefId?: string;
  setSeasonOpen: (value: React.SetStateAction<boolean>) => void;
  seasonDetails?: SeasonsType;
  getListCollection: () => void;
  language: string;
}

export const StyledSelect = styled('div')(() => ({
  width: 'auto',
  legend: {
    display: 'none',
  },
  '.MuiOutlinedInput-input': {
    padding: '5px 32px 5px 10px !important',
    border: `1px solid #5ddb94`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #5ddb94',
  },
  '& MuiMenuItem-root.Mui-selected': {
    backgroundColor: '#d2f4e1',
  },
  fieldset: {
    top: 0,
  },
}));

const Seasons: React.FC<Props> = ({
  collectionRefId,
  setSeasonOpen,
  seasonDetails,
  getListCollection,
  language,
  children,
}) => {
  const [isEpisodeOpen, setEpisodeOpen] = React.useState<boolean>(false);
  const [isCreateEpisodeOpen, setCreateEpisodeOpen] =
    React.useState<boolean>(false);
  const [selectedEpisode, setSelectedEpisode] = React.useState<EpisodeType>();

  const handleEpisodeSelect = (refId: string) => {
    if (seasonDetails?.items.length) {
      setSelectedEpisode(
        seasonDetails.items.filter((item) => item.ref_id === refId)[0]
      );
      setEpisodeOpen(true);
    }
  };

  return (
    <>
      {isCreateEpisodeOpen && (
        <CreateEpisode
          onClose={setCreateEpisodeOpen}
          collectionRefId={collectionRefId}
          seasonRefId={seasonDetails?.ref_id}
          isUpdateEpisode={false}
          getListCollection={getListCollection}
        />
      )}
      {isEpisodeOpen && (
        <Episodes
          episodeDetails={selectedEpisode}
          onClose={setEpisodeOpen}
          getListCollection={getListCollection}
          language={language}
        >
          {seasonDetails?.items?.length && (
            <StyledSelect>
              <Select
                labelId="episode-order-by"
                value={selectedEpisode?.ref_id}
                label="Episode"
                renderValue={() => `Episode - ${selectedEpisode?.order_by}`}
                onChange={(event) => handleEpisodeSelect(event?.target.value)}
              >
                {seasonDetails.items.map((episode) => (
                  <MenuItem
                    disableRipple
                    key={episode.ref_id}
                    value={episode.ref_id}
                  >{`Episode - ${episode.order_by}`}</MenuItem>
                ))}
              </Select>
            </StyledSelect>
          )}
        </Episodes>
      )}
      <div className="season-collection">
        <div className="left-sec">
          <div className="back">
            <svg
              width="25"
              height="18"
              viewBox="0 0 30 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setSeasonOpen(false)}
            >
              <path
                d="M0.939341 10.9393C0.353554 11.5251 0.353554 12.4749 0.939341 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.939341 10.9393ZM30 10.5L2 10.5V13.5L30 13.5V10.5Z"
                fill="#0C3863"
              />
            </svg>
            {children}
          </div>
          <h3 className="heading">Metadata</h3>
          <SeasonPopUp
            refId={collectionRefId}
            onClose={() => null}
            className="not-popup"
            isUpdate={true}
            initialData={{
              title: seasonDetails?.title ?? '',
              description: seasonDetails?.description ?? '',
              ref_id: seasonDetails?.ref_id ?? '',
            }}
            getListCollection={getListCollection}
          />
        </div>
        <div className="right-sec">
          <h3 className="heading">Episodes</h3>
          <UploadAsset
            cardData={{
              index: 1,
              image: EPISODES,
              title: 'Episodes',
              assetType: 'episodes',
              asset: 'Episodes',
              description:
                'Create the desired episodes by clicking the button on the right.',
              button: 'Create',
            }}
            setUploadPopup={setCreateEpisodeOpen}
            setSeasonPopUp={setEpisodeOpen}
            getListCollection={getListCollection}
            language=""
          />
          {seasonDetails && seasonDetails?.items.length > 0
            ? seasonDetails?.items.map((item) => {
                return (
                  <EpisodeViewCard
                    episode={item}
                    handleEpisodeSelect={handleEpisodeSelect}
                    key={item.ref_id}
                  />
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default Seasons;
