import axios from 'axios';
import { LoginFormValues, SignUpValues } from '.';
import Api from '../../../services/api';
import { handleLoginSuccess } from './auth.action';

export const login = (values: LoginFormValues, history: any) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/users/signin/`, {
      email: values.emailId,
      password: values.password,
      fcmRegistrationToken: '',
    })
    .then((res: any) => {
      handleLoginSuccess(res.data, history);
    });
};

export const signup = (values: SignUpValues) => {
  Api.post('', {
    ...values,
  }).then((response: any) => {
    // console.log(response);
  });
};
