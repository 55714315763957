import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Api } from '../../../../../../services';
import { showAlert } from '../../../../../../store';
import './SeasonPopUp.scss';

type Props = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  refId?: string;
  className?: string;
  initialData?: { title: string; description: string; ref_id: string };
  isUpdate: boolean;
  getListCollection: () => void;
};

const SeasonPopUp: React.FC<Props> = ({
  onClose,
  refId,
  className,
  initialData,
  isUpdate,
  getListCollection,
}) => {
  const addSeason = React.useCallback((data, url: string) => {
    Api.post(url, data).then((response) => {
      const message = `Season ${
        isUpdate ? 'updated' : 'created'
      } successfully.`;
      if (response.status === 201 || response.status === 200) {
        onClose(false);
        showAlert({
          alertMessage: message,
          alertType: 'success',
          isAlertVisible: true,
        });
        void getListCollection();
      } else {
        showAlert({
          alertMessage: message,
          alertType: 'error',
          isAlertVisible: true,
        });
      }
    });
  }, []);

  return (
    <div className={`${className ? className : 'SeasonPopUp'}`}>
      <div className="SeasonPopUp-content">
        <div className="SeasonPopUp-header">
          <svg
            width="15"
            height="15"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onClose(false)}
          >
            <path
              d="M24.5 2.91714L22.0829 0.5L12.5 10.0829L2.91714 0.5L0.5 2.91714L10.0829 12.5L0.5 22.0829L2.91714 24.5L12.5 14.9171L22.0829 24.5L24.5 22.0829L14.9171 12.5L24.5 2.91714Z"
              fill="#05396B"
            />
          </svg>
        </div>
        <h3>New Episode</h3>
        <div className="season-form">
          <Formik
            enableReinitialize
            initialValues={
              initialData ?? {
                title: '',
                description: '',
              }
            }
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Required'),
              description: Yup.string().required('Required'),
            })}
            onSubmit={async (values) => {
              const data = isUpdate
                ? { ...values }
                : { ...values, collection_ref_id: refId };
              const url = isUpdate ? 'update_season/' : 'add_season/';
              addSeason(data, url);
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className={`form-wrapper`}>
                  <div className="meta-form">
                    <div className="form-group floating-input">
                      <Field
                        name="title"
                        className={`input-group ${
                          errors.title && touched.title && 'error'
                        }`}
                      />
                      <label className="input-label" htmlFor="name">
                        Name
                      </label>
                      {errors.title && touched.title && (
                        <span className="error-msg">{errors.title}</span>
                      )}
                    </div>
                    <div className="form-group floating-input">
                      <Field
                        name="description"
                        value={values.description}
                        as="textarea"
                        rows="4"
                        className={`input-group
											${errors.description && touched.description && 'error'}`}
                      />
                      <label className="input-label" htmlFor="description">
                        Description
                      </label>
                      {errors.description && touched.description && (
                        <span className="error-msg">{errors.description}</span>
                      )}
                    </div>
                  </div>
                  <div className="submit-btn mt16">
                    <button
                      type="submit"
                      className="submit btn btn-secondary btn-sm"
                    >
                      {initialData ? 'Update' : 'Create'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SeasonPopUp;
