import { Api } from '../../../../../../services';

export const createAd = async (data: { name: string; url: string }) => {
  const response = await Api.post('create_ad/', data);
  if (response) return response;
};

export const fetchAdList = async () => {
  const response = await Api.get('list_ads');
  if (response) return response;
};

export const updateAd = async (data: {
  name: string;
  url: string;
  ref_id: string;
}) => {
  const response = await Api.post('update_ad/', data);
  if (response) return response;
};
