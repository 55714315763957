import React from 'react';

interface Props {
  color: string;
  width: number;
  height: number;
}

const AdsIC: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.0971 17.2319C44.4264 17.2319 42.2413 19.417 42.2413 22.0877V26.9436C42.2413 29.6142 44.4264 31.7994 47.0971 31.7994H51.9529V36.6552H42.2413V41.511H51.9529C54.6236 41.511 56.8087 39.3259 56.8087 36.6552V31.7994C56.8087 29.1287 54.6236 26.9436 51.9529 26.9436H47.0971V22.0877H56.8087V17.2319H47.0971ZM22.818 17.2319V41.511H32.5296C35.2003 41.511 37.3854 39.3259 37.3854 36.6552V22.0877C37.3854 19.417 35.2003 17.2319 32.5296 17.2319H22.818ZM27.6738 22.0877H32.5296V36.6552H27.6738V22.0877ZM8.25059 17.2319C5.57989 17.2319 3.39478 19.417 3.39478 22.0877V41.511H8.25059V31.7994H13.1064V41.511H17.9622V22.0877C17.9622 19.417 15.7771 17.2319 13.1064 17.2319H8.25059ZM8.25059 22.0877H13.1064V26.9436H8.25059V22.0877Z"
        fill={color}
      />
    </svg>
  );
};

export default AdsIC;
