import React from 'react';
import { Field, Form, Formik } from 'formik';
import './MetaDataForm.scss';
import {
  languages,
  subType,
  releaseState,
  FormDataType,
  MetaDataFormSchema,
} from '../../data/dashboard.model';
import { addCollection, updateCollection } from '../../data/dashboard.fetch';
import { addOrUpdateMovie } from '../../../../store';
import { UploadMetaForm } from '../../../../store/data';
import { useHistory } from 'react-router-dom';

type Props = {
  initialMetaData: FormDataType;
  isMultipart?: boolean;
  isUpdate?: boolean;
};

const MetaDataForm: React.FC<Props> = ({
  initialMetaData,
  isMultipart,
  isUpdate,
}) => {
  const history = useHistory();
  const toggleForm = React.useCallback(
    (addMovie: UploadMetaForm) => addOrUpdateMovie(addMovie),
    []
  );

  return (
    <div className={`${isUpdate ? '' : 'form-popup'}`}>
      <Formik
        initialValues={initialMetaData}
        validationSchema={MetaDataFormSchema(isMultipart)}
        onSubmit={async (values) => {
          let data: FormDataType = {
            title: values.title,
            is_multipart:
              isMultipart === undefined
                ? initialMetaData.is_multipart
                : isMultipart,
            language: values.language,
            genre: values.genre,
            rating: values.rating,
            description: values.description,
            paid: Boolean(values.paid),
            sub_type: Number(values.sub_type),
            studio: values.studio,
            release_state: Number(values.release_state),
            release_date: new Date(values.release_date).toISOString(),
            duration: values.duration,
            rating_tags: values.rating_tags,
          };
          if (!isUpdate) {
            addCollection({
              method: isMultipart ? 'add_collection/' : 'add_movie/',
              data,
              history,
            });
          } else {
            data.ref_id = initialMetaData.ref_id;
            updateCollection({
              method: initialMetaData.is_multipart
                ? 'update_collection/'
                : 'update_movie/',
              data,
            });
          }
        }}
      >
        {({ values, errors, touched }) => (
          <Form>
            <h3 className="form-title">Metadata</h3>
            <div
              className={`${
                isUpdate ? 'form-wrapper' : 'form-wrapper form-max-height'
              }`}
            >
              <div className="meta-form">
                <div className="form-group floating-input">
                  <Field
                    name="title"
                    className={`input-group ${
                      errors.title && touched.title && 'error'
                    }`}
                  />
                  <label className="input-label" htmlFor="title">
                    Title
                  </label>
                  {errors.title && touched.title && (
                    <span className="error-msg">{errors.title}</span>
                  )}
                </div>
                <div className="form-group floating-input">
                  <Field
                    name="genre"
                    value={values.genre}
                    className={`input-group
											${errors.genre && touched.genre && 'error'}`}
                  />
                  <label className="input-label" htmlFor="genre">
                    Genre
                  </label>
                  {errors.genre && touched.genre && (
                    <span className="error-msg">{errors.genre}</span>
                  )}
                </div>

                <div className="form-group floating-input w50">
                  <Field
                    name="language"
                    as="select"
                    className={`input-group ${
                      errors.language && touched.language && 'error'
                    }`}
                  >
                    <option hidden>Select language</option>
                    {languages.map((item) => {
                      return (
                        <option key={item.code} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Field>
                  <label className="input-label" htmlFor="languages">
                    Languages
                  </label>
                  {errors.language && touched.language && (
                    <span className="error-msg">{errors.language}</span>
                  )}
                </div>
                <div className="form-group floating-input w50">
                  <Field
                    name="paid"
                    as="select"
                    className={`input-group
											${errors.paid && touched.paid && 'error'}`}
                  >
                    <option value="" hidden>
                      Select below
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <label className="input-label" htmlFor="paid">
                    Paid
                  </label>
                  {errors.paid && touched.paid && (
                    <span className="error-msg">{errors.paid}</span>
                  )}
                </div>
                <div className="form-group floating-input w50">
                  <Field
                    name="sub_type"
                    as="select"
                    className={`input-group
											${errors.sub_type && touched.sub_type && 'error'}`}
                  >
                    <option value="" hidden>
                      Select below
                    </option>
                    {subType.map((item) => {
                      return (
                        <option key={item.name} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Field>
                  <label className="input-label" htmlFor="sub_type">
                    Content Subtype
                  </label>
                  {errors.sub_type && touched.sub_type && (
                    <span className="error-msg">{errors.sub_type}</span>
                  )}
                </div>
                <div className="form-group floating-input w50">
                  <Field
                    name="release_state"
                    as="select"
                    className={`input-group
											${errors.release_state && touched.release_state && 'error'}`}
                  >
                    <option value="" hidden>
                      Select below
                    </option>
                    {releaseState.map((item) => {
                      return (
                        <option key={item.name} value={item.value}>
                          {item.title}
                        </option>
                      );
                    })}
                  </Field>
                  <label className="input-label" htmlFor="release_state">
                    Release State
                  </label>
                  {errors.release_state && touched.release_state && (
                    <span className="error-msg">{errors.release_state}</span>
                  )}
                </div>

                <div className="form-group floating-input w50">
                  <Field
                    name="release_date"
                    type="date"
                    className={`input-group
											${errors.release_date && touched.release_date && 'error'}`}
                  />
                  <label className="input-label" htmlFor="release_date">
                    Release Date {'&'} time
                  </label>
                  {errors.release_date && touched.release_date && (
                    <span className="error-msg">{errors.release_date}</span>
                  )}
                </div>
                {!isMultipart && (
                  <div className="form-group floating-input w50">
                    <Field
                      name="duration"
                      placeholder="01Hr 59Min"
                      className={`input-group
											${errors.duration && touched.duration && 'error'}`}
                    />
                    <label className="input-label" htmlFor="duration">
                      Duration
                    </label>
                    {errors.duration && touched.duration && (
                      <span className="error-msg duration-error">
                        {errors.duration}
                      </span>
                    )}
                  </div>
                )}
                <div className="form-group floating-input w50">
                  <Field
                    name="rating"
                    className={`input-group
											${errors.rating && touched.rating && 'error'}`}
                  />
                  <label className="input-label" htmlFor="rating">
                    Rating
                  </label>
                  {errors.rating && touched.rating && (
                    <span className="error-msg">{errors.rating}</span>
                  )}
                </div>
                {!isMultipart && (
                  <div className="form-group floating-input w50">
                    <Field
                      name="rating_tags"
                      className={`input-group
											${errors.rating_tags && touched.rating_tags && 'error'}`}
                    />
                    <label className="input-label" htmlFor="rating_tags">
                      Rating Tags
                    </label>
                    {errors.rating_tags && touched.rating_tags && (
                      <span className="error-msg">{errors.rating_tags}</span>
                    )}
                  </div>
                )}
                <div className="form-group floating-input">
                  <Field
                    name="studio"
                    className={`input-group
											${errors.studio && touched.studio && 'error'}`}
                  />
                  <label className="input-label" htmlFor="studio">
                    Studio
                  </label>
                  {errors.studio && touched.studio && (
                    <span className="error-msg">{errors.studio}</span>
                  )}
                </div>
                <div className="form-group floating-input">
                  <Field
                    name="description"
                    className={`input-group ${
                      errors.description && touched.description && 'error'
                    }`}
                  />
                  <label className="input-label" htmlFor="description">
                    Description
                  </label>
                  {errors.description && touched.description && (
                    <span className="error-msg">{errors.description}</span>
                  )}
                </div>
              </div>
              <div
                className={`${
                  isUpdate ? 'action-row text-right' : 'action-row text-center'
                }`}
              >
                {!isUpdate && (
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={() =>
                      toggleForm({
                        isMetaDataFormPopUp: false,
                        isUpdate: false,
                      })
                    }
                  >
                    Cancel
                  </button>
                )}

                <button
                  className={`${
                    isUpdate
                      ? 'submit btn btn-secondary btn-sm'
                      : 'submit btn btn-primary btn-sm'
                  }`}
                  type="submit"
                >
                  {isUpdate ? 'Update' : 'Submit'}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MetaDataForm;
