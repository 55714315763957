import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Collections } from '../components/Collections';
import '../Dashboard.scss';
import { sidebarList } from '../components/Sidebar/data/sidebar.constants';

const ContentManager = () => {
  return (
    <div className="dashboard">
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <div className="dashboard-main">
        <ErrorBoundary>
          <Sidebar routes={sidebarList} />
        </ErrorBoundary>
        <ErrorBoundary>
          <Collections />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default ContentManager;
