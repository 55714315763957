import React, { useState } from 'react';
import { CLOSE } from '../../../../../assets';
import { showAlert } from '../../../../../store';
import {
  FAILED_TO_UPLOAD,
  UPLOAD_SUCCESSFUL,
  UPLOAD_URL_CREATED,
} from '../../../../../utils/app.constants';
import { handleApiError } from '../../../../../utils/utilityService';
import { CollectionData } from '../../../data';
import AddCasts from '../AddCasts';
import {
  assetUploadStatus,
  CardData,
  CastMember,
  getUploadUrl,
  PostFormData,
  uploadFileToBucket,
} from '../data';
import { FileSelect } from './FileSelect';
import { FileUpload } from './FileUpload';
import { FileUploadProgress } from './FileUploadProgress';
import { FileUploadSuccess } from './FileUploadSuccess';
import './MasterUpload.scss';

type Props = {
  cardData?: CardData;
  videoData: {
    cast_members?: Array<CastMember>;
    collection: CollectionData;
    directors?: Array<string>;
  };
  inputType?: string;
  setUploadPopup: React.Dispatch<React.SetStateAction<boolean>>;
  addedCastMember?: CastMember[];
  setAddedCastMember?: React.Dispatch<React.SetStateAction<CastMember[]>>;
  getListCollection: () => void;
  level: string;
  language: string;
};

export const MasterUpload = (props: Props) => {
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [preSignedUrl, setPreSignedUrl] = useState<PostFormData>();
  // const [isUploading, setUploading] = useState<boolean>(false);
  const [assetUploadStatusData, setUploadAssetStatus] = useState<any>();
  const [uploadingPercentage, setUploadingPercentage] = useState<number>(0);
  const [fileUploadSteps, setFileUploadSteps] = useState<string>('file-select');

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files && event.target.files[0];
      setSelectedFile(file);
      getPresignedUrl();
    } catch (error) {
      console.error(error);
    }
  };

  const meta = React.useMemo(() => {
    if (
      props.language &&
      (props.cardData?.assetType === 'subtitle' ||
        props.cardData?.assetType === 'audio')
    ) {
      return { language: props.language };
    }
    return {};
  }, [props.cardData?.assetType, props.language]);

  const getPresignedUrl = () => {
    try {
      const data = {
        level: props.level,
        ref_id: props?.videoData?.collection?.ref_id ?? '',
        asset_type: props.cardData?.assetType.toLowerCase(),
        meta: meta,
      };
      if (data) {
        getUploadUrl(data).then((response: any) => {
          const data: PostFormData = response.data.url;
          if (response.data && response.data.url) {
            if (response.status === 201 || response.status === 200) {
              setPreSignedUrl(data);
              setUploadAssetStatus(response.data);
              setFileUploadSteps('file-upload');
              showAlert({
                alertMessage: UPLOAD_URL_CREATED,
                alertType: 'success',
                isAlertVisible: true,
              });
            } else {
              showAlert({
                alertMessage: FAILED_TO_UPLOAD,
                alertType: 'success',
                isAlertVisible: true,
              });
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadToS3Bucket = async () => {
    setFileUploadSteps('upload-progress');
    try {
      const formData = new FormData();
      if (preSignedUrl) {
        Object.entries(preSignedUrl.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
      }
      formData.append('file', selectedFile);
      if (preSignedUrl) {
        uploadFileToBucket(preSignedUrl.url, formData, setUploadingPercentage)
          .then((response: any) => {
            if (response.status === 204) {
              setFileUploadSteps('upload-success');
              showAlert({
                alertMessage: UPLOAD_SUCCESSFUL,
                alertType: 'success',
                isAlertVisible: true,
              });
              if (assetUploadStatusData)
                assetUploadStatus({
                  id: assetUploadStatusData.id,
                  name: assetUploadStatusData.name,
                });
              props.getListCollection();
            } else {
              setFileUploadSteps('file-upload');
              showAlert({
                alertMessage: FAILED_TO_UPLOAD,
                alertType: 'error',
                isAlertVisible: true,
              });
            }
          })
          .catch((error) => {
            setFileUploadSteps('file-upload');
            handleApiError(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isProgressActive = (step: string) => {
    return `${fileUploadSteps === step ? 'is-active' : null}`;
  };

  return (
    <div className="master-upload">
      <div className="master-content">
        <div className="master-header">
          <h3 className="upload-title">Add {props.cardData?.title}</h3>
          <img
            src={CLOSE}
            alt="close"
            className="pointer"
            onClick={() => props.setUploadPopup(false)}
          />
        </div>
        {props.cardData?.assetType === 'movieCast' ? (
          <>
            {props?.addedCastMember && props?.setAddedCastMember && (
              <AddCasts
                videoData={props?.videoData}
                addedCastMember={props.addedCastMember}
                setAddedCastMember={props.setAddedCastMember}
                getListCollection={props.getListCollection}
              />
            )}
          </>
        ) : (
          <>
            <h3 className="upload-title">Upload {props.cardData?.title}</h3>
            <div className="upload-progress">
              <div className="container-fluid">
                <ul className="list-unstyled multi-steps">
                  {[
                    'file-select',
                    'file-upload',
                    'upload-progress',
                    'upload-success',
                  ].map((step, index) => (
                    <li
                      key={index}
                      {...(index === 0 && fileUploadSteps === 'file-upload'
                        ? {
                            className: `${isProgressActive(
                              step
                            )} cursor-pointer`,
                            onClick: () => setFileUploadSteps('file-select'),
                            title: 'Select file',
                          }
                        : {
                            className: `${isProgressActive(step)}`,
                          })}
                    ></li>
                  ))}
                </ul>
              </div>
            </div>
            <FileSelect
              handleFileUpload={handleFileUpload}
              fileUploadSteps={fileUploadSteps}
              title={props.cardData?.title}
            />
            <FileUpload
              fileUploadSteps={fileUploadSteps}
              uploadToS3Bucket={uploadToS3Bucket}
              selectedFile={selectedFile}
            />
            <FileUploadProgress
              fileUploadSteps={fileUploadSteps}
              uploadingPercentage={uploadingPercentage}
            />
            <FileUploadSuccess
              fileUploadSteps={fileUploadSteps}
              setFileUploadSteps={setFileUploadSteps}
            />
          </>
        )}
      </div>
    </div>
  );
};
