import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Api } from '../../../../services';
import { showAlert } from '../../../../store';
import { EpisodeType } from '../../../Dashboard/data';
import './CreateEpisode.scss';

type Props = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  collectionRefId?: string;
  seasonRefId?: string;
  className?: string;
  initialData?: EpisodeType;
  isUpdateEpisode: boolean;
  getListCollection: () => void;
};

interface EpisodeItem {
  title: string;
  episode_number?: number;
  description: string;
  duration: string;
  rating_tags: string;
  rating: string;
  collection_ref_id?: string;
  season_ref_id?: string;
}

const CreateEpisode: React.FC<Props> = ({
  onClose,
  collectionRefId,
  seasonRefId,
  className,
  initialData,
  isUpdateEpisode,
  getListCollection,
}) => {
  const addEpisode = (data: EpisodeItem, url: string) => {
    Api.post(url, data).then((response) => {
      const message = `Episode ${
        isUpdateEpisode ? 'updated' : 'added'
      } successfully.`;
      if (response.status === 201 || response.status === 200) {
        onClose(false);
        showAlert({
          alertMessage: message,
          alertType: 'success',
          isAlertVisible: true,
        });
        void getListCollection();
      } else {
        showAlert({
          alertMessage: message,
          alertType: 'error',
          isAlertVisible: true,
        });
      }
    });
  };

  const initialFormData = React.useMemo(() => {
    return (
      initialData ?? {
        title: '',
        description: '',
        duration: '',
        rating_tags: '',
      }
    );
  }, [initialData]);

  return (
    <div className={`${className ? className : 'SeasonPopUp'} episode-pop-up`}>
      <div className="SeasonPopUp-content">
        <div className="SeasonPopUp-header">
          <svg
            width="15"
            height="15"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onClose(false)}
          >
            <path
              d="M24.5 2.91714L22.0829 0.5L12.5 10.0829L2.91714 0.5L0.5 2.91714L10.0829 12.5L0.5 22.0829L2.91714 24.5L12.5 14.9171L22.0829 24.5L24.5 22.0829L14.9171 12.5L24.5 2.91714Z"
              fill="#05396B"
            />
          </svg>
        </div>
        <h3>New Episode</h3>
        <div className="season-form">
          <Formik
            initialValues={initialFormData}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Required'),
              description: Yup.string().required('Required'),
              duration: Yup.string()
                .matches(
                  /^(?:(?:([0-9]?\d{0,2}|2[0-9])Hr[' '])?(?:[0-5][0-9]?\d{0})Min)$/gm,
                  `Entered text must match the following format 10Hr 59Min.
                      Hr must be less than or equal to three digit and
                      minute must be less than 60.`
                )
                .required('Required'),
              rating_tags: Yup.string().required('Required'),
            })}
            onSubmit={(values) => {
              const url = isUpdateEpisode ? 'update_item/' : 'add_item/';
              const data = isUpdateEpisode
                ? { ...values }
                : {
                    ...values,
                    collection_ref_id: collectionRefId,
                    season_ref_id: seasonRefId,
                  };
              addEpisode(data as unknown as EpisodeItem, url);
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <div className={`form-wrapper`}>
                  <div className="meta-form">
                    <div className="form-group floating-input">
                      <Field
                        name="title"
                        className={`input-group ${
                          errors.title && touched.title && 'error'
                        }`}
                      />
                      <label className="input-label" htmlFor="name">
                        Name
                      </label>
                      {errors.title && touched.title && (
                        <span className="error-msg">{errors.title}</span>
                      )}
                    </div>
                    <div className="form-group floating-input">
                      <Field
                        name="description"
                        value={values.description}
                        as="textarea"
                        rows="4"
                        className={`input-group ${
                          touched.description && 'error'
                        }`}
                      />
                      <label className="input-label" htmlFor="description">
                        Description
                      </label>
                      {errors.description && touched.description && (
                        <span className="error-msg">{errors.description}</span>
                      )}
                    </div>
                    <div className="form-group floating-input">
                      <Field
                        name="duration"
                        className={`input-group
											${errors.duration && touched.duration && 'error'}`}
                      ></Field>
                      <label className="input-label" htmlFor="duration">
                        Duration
                      </label>
                      {errors.duration && touched.duration && (
                        <span className="error-msg">{errors.duration}</span>
                      )}
                    </div>
                    <div className="form-group floating-input">
                      <Field
                        name="rating_tags"
                        as="select"
                        value={values.rating_tags}
                        rows="4"
                        className={`input-group  ${
                          touched.rating_tags && 'error'
                        }`}
                      >
                        <option hidden>Select Rating</option>
                        <option value="A">A</option>
                        <option value="PG">PG</option>
                      </Field>
                      <label className="input-label" htmlFor="rating_tags">
                        Rating Tags
                      </label>
                      {errors.rating_tags && touched.rating_tags && (
                        <span className="error-msg">{errors.rating_tags}</span>
                      )}
                    </div>
                  </div>
                  <div className="submit-btn mt16">
                    <button
                      type="submit"
                      className="submit btn btn-secondary btn-sm"
                    >
                      {isUpdateEpisode ? 'Update' : 'Create'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateEpisode;
