import './App.scss';
import { Alert } from './components/Alert';
import Loader from './components/Loader/Loader';
import { connect } from 'react-redux';
import MainNavigation from './mainNavigation';
import { InitialState } from './store/data';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
    return (
        <div className="App">
            <ErrorBoundary>
                <Alert />
                <Loader />
                <MainNavigation />
            </ErrorBoundary>
        </div>
    );
}

const mapStateToProps = (state: InitialState) => {
    return {
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading,
        alertMessage: state.alertMessage,
    };
};

export default connect(mapStateToProps, {})(App);
