import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import {
  InitialState,
  SHOW_ALERT,
  initialState,
  LOGIN,
  User,
  ADD_MOVIE,
  LOADING,
  HANDLE_UNAUTHORIZED,
} from './data';

export const reducer = (
  state: InitialState = initialState,
  action: any
): InitialState => {
  switch (action.type) {
    case LOGIN:
      const newLogin: User = {
        email: action.user.email,
        accessToken: action.user.accessToken,
        associated_teams: action.user.associated_teams,
      };
      return { ...state, isAuthenticated: true, ...newLogin };
    case SHOW_ALERT:
      return {
        ...state,
        alertType: action.alert.alertType,
        alertMessage: action.alert.alertMessage,
        alertTime: action.alert.alertTime ? action.alert.alertTime : 10000,
        isAlertVisible: action.alert.isAlertVisible,
      };
    case ADD_MOVIE:
      return {
        ...state,
        addMovie: {
          isMetaDataFormPopUp: action.addMovie.isMetaDataFormPopUp,
          isUpdate: action.addMovie.isUpdate,
        },
      };
    case LOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case HANDLE_UNAUTHORIZED:
      return {
        ...state,
        isAuthenticated: false,
        user: { accessToken: '', email: '', associated_teams: null },
      };
  }
  return state;
};

const store: Store<InitialState, any> & {
  dispatch: any;
} = createStore(reducer, initialState, applyMiddleware(thunk));

export default store;
