import * as React from 'react';
import './PageNotFound.scss'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div className="page-not-found">
            <div className="text-content">
                <h1>404</h1>
                <h2>Sorry, page not found.</h2>
                <p>Click 
                    <Link to="/">{` here `}</Link>
                    to visit home page.
                </p>
            </div>
        </div>
    );
};
export default PageNotFound