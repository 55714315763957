import React from 'react';

interface Props {
  color: string;
  width: number;
  height: number;
}

const PlaylistIC: React.FC<Props> = ({ color = '#06396a', width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10H0V45C0 47.75 2.25 50 5 50H40V45H5V10ZM45 0H15C12.25 0 10 2.25 10 5V35C10 37.75 12.25 40 15 40H45C47.75 40 50 37.75 50 35V5C50 2.25 47.75 0 45 0ZM45 35H15V5H45V35ZM25 8.75V31.25L40 20L25 8.75Z"
        fill={color}
      />
    </svg>
  );
};

export default PlaylistIC;
