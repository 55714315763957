import * as Yup from 'yup';

export interface FormDataType {
  ref_id?: string;
  title: string;
  language: string;
  genre: string;
  rating: string;
  description: string;
  paid?: boolean;
  assets?: CollectionAsset;
  is_multipart?: boolean;
  season_ref_id?: string;
  seasons?: Array<SeasonsType>;
  sub_type?: number;
  studio: string;
  release_state?: number;
  release_date: string;
  duration: string;
  rating_tags: string;
}

export interface EpisodeType {
  active: boolean;
  collection_id: number;
  description: string;
  duration: string;
  language?: string;
  is_multipart?: boolean;
  season_ref_id?: string;
  episode_number?: number;
  rating?: string;
  order_by: number;
  prefix: string;
  rating_tags: string;
  ref_id: string;
  season_id: number;
  thumbnails: string;
  title: string;
  assets: CollectionAsset;
}

export interface SeasonsType {
  active: boolean;
  artists: Array<string>;
  collection_id: number;
  cost: {};
  currency: number;
  description: string;
  items: Array<EpisodeType>;
  order_by: number;
  prefix: string;
  ref_id: string;
  thumbnails: string;
  title: string;
}

export interface CollectionAsset {
  thumbnails: Assets[];
  subtitles: Assets[];
  videos: Assets[];
  audios: Assets;
}

export type CollectionData = FormDataType | EpisodeType;

export interface Assets {
  active: boolean;
  id: number;
  live: boolean;
  name: string;
  path: string;
  version: string;
}

export const initialValues: FormDataType = {
  title: '',
  language: '',
  genre: '',
  rating: '',
  description: '',
  paid: undefined,
  sub_type: undefined,
  studio: '',
  release_state: undefined,
  release_date: '',
  duration: '',
  rating_tags: '',
};

export const languages = [
  { name: 'English', code: 'en', native_name: 'English' },
  { name: 'Hindi', code: 'hi', native_name: 'हिंदी' },
  { name: 'Bengali', code: 'bn', native_name: 'বাংলা' },
  { name: 'Marathi', code: 'mr', native_name: 'मराठी' },
  { name: 'Telugu', code: 'te', native_name: 'తెలుగు' },
  { name: 'Tamil', code: 'ta', native_name: 'தமிழ்' },
  { name: 'Gujarati', code: 'gu', native_name: 'ગુજરાતી' },
  { name: 'Urdu', code: 'ur', native_name: 'اردو' },
  { name: 'Kannada', code: 'kn', native_name: 'ಕನ್ನಡ' },
  { name: 'Oriya', code: 'or', native_name: 'ଓଡ଼ିଆ' },
  { name: 'Malayalam', code: 'ml', native_name: 'മലയാളം' },
  { name: 'Punjabi', code: 'pa', native_name: 'ਪੰਜਾਬੀ' },
];

export const subType = [
  { name: 'Chatshow', value: 0 },
  { name: 'Skits', value: 1 },
  { name: 'Play', value: 2 },
  { name: 'Stories', value: 3 },
  { name: 'Documentary', value: 4 },
  { name: 'Standup', value: 5 },
  { name: 'Shortseries', value: 6 },
  { name: 'Limitedseries', value: 7 },
  { name: 'Normal', value: 8 },
  { name: 'Shortfilm', value: 9 },
  { name: 'Podcast', value: 10 },
];

export const releaseState = [
  { name: 'not_release', title: 'Not release', value: 0 },
  { name: 'controlled_release', title: 'Controlled release', value: 1 },
  { name: 'coming_soon', title: 'Coming soon', value: 2 },
  { name: 'released', title: 'Released', value: 3 },
];

export const MetaDataFormSchema = (isMultiPart?: boolean) => {
  const ObjectSchema = {
    title: Yup.string().required('Required'),
    language: Yup.string().required('Required'),
    genre: Yup.string().required('Required'),
    rating: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    paid: Yup.boolean().required('Required'),
    sub_type: Yup.number().required('Required'),
    studio: Yup.string().required('Required'),
    release_state: Yup.number().required('Required'),
    release_date: Yup.string().required('Required'),
  };
  if (isMultiPart) {
    return Yup.object().shape(ObjectSchema);
  }
  return Yup.object().shape({
    ...ObjectSchema,
    duration: Yup.string()
      .matches(
        /^(?:(?:([0-9]?\d{0,2}|2[0-9])Hr[' '])?(?:[0-5][0-9]?\d{0})Min)$/gm,
        `Entered text must match the following format 10Hr 59Min.
            Hr must be less than or equal to three digit and
            minute must be less than 60.`
      )
      .required('Required'),
    rating_tags: Yup.string().required('Required'),
  });
};

export interface CollectionDetails {
  banners: string;
  active?: boolean;
  featured?: boolean;
  description: string;
  genre: string;
  is_multipart: boolean;
  language: string;
  paid: boolean;
  rating: string;
  ref_id: string;
  release_date: string;
  release_state: number;
  studio: string;
  sub_type: number;
  thumbnails: string;
  title: string;
}
