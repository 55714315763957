import React from 'react';
import { CardData } from '../../data';

type Props = {
  cardData?: CardData;
  handlePopUp: () => void;
};

const UploadAssetCard: React.FC<Props> = ({ cardData, handlePopUp }) => {
  return (
    <div className="video-asset-card">
      <div className="asset-image">
        {cardData && (
          <img src={cardData?.image} alt={cardData?.title} loading="lazy" />
        )}
      </div>
      <div className="asset-info">
        {cardData && (
          <>
            <h3 className="asset-heading">{cardData?.title}</h3>
            <p className="asset-description">{cardData?.description}</p>
          </>
        )}
      </div>
      <div className="asset-action">
        {cardData && (
          <button className="btn btn-secondary btn-sm" onClick={handlePopUp}>
            {cardData?.button}
          </button>
        )}
      </div>
    </div>
  );
};
export default UploadAssetCard;
