import * as Yup from 'yup';

export interface LoginFormValues {
  emailId: string;
  password: string;
}

export const loginValidators = Yup.object().shape({
  emailId: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

export const loginInitialValues: LoginFormValues = {
  emailId: '', //'editor@flowgiga.com',
  password: '', //'editorpassword',
};

export interface SignUpValues {
  fullName: string;
  companyName: string;
  phoneNumber: number | undefined;
  emailId: string;
  password: string;
  domainName: string;
}

export const signUpValidators = Yup.object().shape({
  fullName: Yup.string()
    .min(3, 'Minimum character is 3')
    .max(50, 'Maximum character is 50')
    .required('Required'),
  companyName: Yup.string()
    .min(3, 'Minimum character is 3')
    .max(50, 'Maximum character is 50')
    .required('Required'),
  phoneNumber: Yup.number()
    .test(
      'len',
      'Must be exactly 10 digits',
      (val) => val?.toString().length === 10
    )
    .required('Required'),
  emailId: Yup.string().email('Inavalid email.').required('Required'),
  password: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      'Password must contain at least one number and one special character'
    )
    .required('Required'),
  domainName: Yup.string().required('Required'),
});

export const signUpInitialValues: SignUpValues = {
  fullName: '',
  companyName: '',
  phoneNumber: undefined,
  emailId: '',
  password: '',
  domainName: '',
};

export interface HandleLogin {
  values: LoginFormValues;
  history: any;
}
