import {
  SHOW_ALERT,
  LOGIN,
  AlertType,
  User,
  ADD_MOVIE,
  UploadMetaForm,
  LOADING,
  HANDLE_UNAUTHORIZED,
} from './data';
import store from './reducer';

export const showAlert = (alert: AlertType) => {
  store.dispatch({
    type: SHOW_ALERT,
    alert,
  });
};

export const loginDispatch = (user: User) => {
  store.dispatch({
    type: LOGIN,
    user,
  });
};

export const addOrUpdateMovie = (addMovie: UploadMetaForm) => {
  store.dispatch({
    type: ADD_MOVIE,
    addMovie,
  });
};

export const toggleLoader = () => {
  store.dispatch({
    type: LOADING,
  });
};

export const logOutUser = () => {
  store.dispatch({
    type: HANDLE_UNAUTHORIZED,
  });
};
