import { setLocalAccessToken } from '../../../services/api';
import { loginDispatch, showAlert } from '../../../store';
import {
  LOGIN_SUCCESS,
  SOMETHING_WENT_WRONG,
} from '../../../utils/app.constants';
import {
  encodeObjectUsingBTOA,
  setToLocalStorage,
} from '../../../utils/utilityService';

export const handleLoginSuccess = (response: any, history: any) => {
  try {
    setToLocalStorage(
      'userInformation',
      encodeObjectUsingBTOA({
        ...response,
        selectedRole: response.associated_teams[0],
      })
    );
    if (response.token && response.email) {
      loginDispatch(response);
      setLocalAccessToken(response.token);
      showAlert({
        alertMessage: LOGIN_SUCCESS,
        alertType: 'success',
        isAlertVisible: true,
      });
      history.push('/');
    } else {
      showAlert({
        alertMessage: SOMETHING_WENT_WRONG,
        alertType: 'success',
        isAlertVisible: true,
      });
    }
  } catch (error) {
    showAlert({
      alertMessage: SOMETHING_WENT_WRONG,
      alertType: 'error',
      isAlertVisible: true,
    });
    console.error(error);
  }
};
