import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { PLUS } from '../../../../../../assets';
import AdTags from '../AdTags';
import { AdTag, fetchAdList, TagAPIResponse, updateAd } from '../data';
import TagCard from '../TagCard';
import './Advertisements.scss';

const Advertisements: React.FC = () => {
  const [selectedAd, setSelectedAd] = useState<AdTag | null>(null);
  const [isPopup, setPopUp] = useState<boolean>(false);
  const [ads, setAds] = useState<AdTag[]>([]);

  const getAds = React.useCallback(() => {
    fetchAdList()
      .then((response: AxiosResponse<any, any> | undefined) => {
        if (response && response.data && response.data.data) {
          const result = response.data.data as TagAPIResponse;
          setAds(result.results);
        }
      })
      .catch((err) => {
        console.error(`Error - >> ${err}`);
      });
  }, []);

  useEffect(() => {
    getAds();
  }, [getAds, isPopup]);

  const createNewAd = () => {
    setPopUp(true);
    setSelectedAd(null);
  };

  const updateAdtag = (data: { name: string; url: string; ref_id: string }) => {
    if (data)
      updateAd(data)
        .then((response) => {
          if (response?.status === 200 && response) {
            setPopUp(false);
            const updatedData = response.data as AdTag;
            setAds((prev) =>
              updatedData.ref_id
                ? prev.map((ad) =>
                    ad.ref_id === updatedData.ref_id ? updatedData : ad
                  )
                : [...prev]
            );
          }
        })
        .catch((error) => {
          console.error(`erro ->>>${error}`);
        });
  };
  return (
    <>
      <div className="content">
        <div className="content-heading">
          <h3 className="heading">In video ads</h3>
          <button className="btn btn-secondary" onClick={createNewAd}>
            <img src={PLUS} alt="plus" />
            add
          </button>
        </div>
        <div className="tags">
          {ads?.map((tag) => (
            <TagCard
              key={tag.ref_id}
              tag={tag}
              setSelectedAd={setSelectedAd}
              setPopUp={setPopUp}
            />
          ))}
        </div>
      </div>
      {isPopup && (
        <AdTags
          setPopUp={setPopUp}
          selectedAd={selectedAd}
          updateAdtag={updateAdtag}
        />
      )}
    </>
  );
};

export default Advertisements;
