import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import Header from '../Header';
import { COPYRIGHT, SIGN_UP, SIGN_IN } from '../../../../utils/app.constants';
import { MAN_WITH_CAMERA } from '../../../../assets';
import {
  loginValidators,
  loginInitialValues,
  LoginFormValues,
  login,
} from '../../data';
import { LOGIN, REGISTER } from '../../../../utils/routes.constants';
import { useHistory } from 'react-router';
import './Login.scss';

const Login = () => {
  const history = useHistory();
  const handleLogin = async (values: LoginFormValues) => {
    login(values, history);
  };

  return (
    <div className="login">
      <Header />
      <div className="login-form">
        <div className="login-form-left-sec">
          <img src={MAN_WITH_CAMERA} alt="man with camera" loading="lazy" />
        </div>
        <div className="login-form-right-sec">
          <div className="auth-btn">
            <Link className="active" to={LOGIN}>
              {SIGN_IN}
            </Link>
            <Link to={REGISTER}>{SIGN_UP}</Link>
          </div>
          <Formik
            initialValues={loginInitialValues}
            validationSchema={loginValidators}
            onSubmit={handleLogin}
          >
            {({ errors, touched }) => {
              return (
                <>
                  <Form>
                    <div className="form-group">
                      <Field
                        className={`input-group ${
                          errors.emailId && touched.emailId && 'error'
                        }`}
                        name="emailId"
                        placeholder="Email Id"
                      />
                      {errors.emailId && touched.emailId && (
                        <span className="error-msg">{errors.emailId}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        className={`input-group ${
                          errors.password && touched.password && 'error'
                        }`}
                        name="password"
                        placeholder="Password"
                        type="password"
                      />
                      {errors.password && touched.password && (
                        <span className="error-msg">{errors.password}</span>
                      )}
                    </div>
                    <div className="action-row">
                      <button
                        className="btn btn-primary btn-lg btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="copyright">{COPYRIGHT}</div>
    </div>
  );
};

export default Login;
