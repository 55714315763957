import React, { useState } from 'react';
import './Monitization.scss';
import '../VideoManager.scss';
import AdsIC from '../../../../../assets/AdsIC';
import CardIC from '../../../../../assets/CardIC';
import PlayCheckIC from '../../../../../assets/PlayCheckIC';
import TabContent from './TabContent';

const Monitization: React.FC = () => {
  const [selectedType, setSelectedType] = useState<string>('advertisements');

  return (
    <div className="monitization">
      <h2 className="heading">Monitization</h2>
      <div className="monitization-cards">
        <div className="card">
          <input
            type="radio"
            id="subscription"
            name="monitization-type"
            value="subscription"
            checked={selectedType === 'subscription'}
          />
          <label
            htmlFor="subscription"
            onClick={() => setSelectedType('subscription')}
          >
            <PlayCheckIC
              width={40}
              height={30}
              color={selectedType === 'subscription' ? '#fff' : '#5ddb94'}
            />
            Subscription
          </label>
        </div>
        <div className="card">
          <input
            type="radio"
            id="Pay-Per-View"
            name="monitization-type"
            value="payPerView"
            checked={selectedType === 'payPerView'}
          />
          <label
            htmlFor="Pay-Per-View"
            onClick={() => setSelectedType('payPerView')}
          >
            <CardIC
              width={40}
              height={30}
              color={selectedType === 'payPerView' ? '#fff' : '#5ddb94'}
            />
            Pay-Per-View
          </label>
        </div>
        <div className="card">
          <input
            type="radio"
            id="advertisements"
            name="monitization-type"
            value="advertisements"
            checked={selectedType === 'advertisements'}
          />
          <label
            htmlFor="advertisements"
            onClick={() => setSelectedType('advertisements')}
          >
            <AdsIC
              width={40}
              height={30}
              color={selectedType === 'advertisements' ? '#fff' : '#5ddb94'}
            />
            Advertisements
          </label>
        </div>
      </div>
      <TabContent selectedType={selectedType} />
    </div>
  );
};

export default Monitization;
