import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Switch from '@mui/material/Switch';
import { THUMBNAIL_NOT_AVAILABLE } from '../../../../assets';
import { MY_VIDEO } from '../../../../utils/routes.constants';
import ReadMore from '../../../ReadMore/ReadMore';
import { CollectionDetails, subType } from '../../data/dashboard.model';
import {
  getCollectionData,
  toggleActiveStatus,
  toggleFeaturedStatus,
} from '../../data';
import './ContentCard.scss';
import { AxiosResponse } from 'axios';
import { handleUncaughtError } from '../../../../utils/utilityService';

type Props = {
  details: CollectionDetails;
};

const ContentCard: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [isActiveChecked, setActiveChecked] = useState<boolean | undefined>(
    props.details.active
  );
  const [isFeaturedChecked, setFeaturedChecked] = useState<boolean | undefined>(
    props.details.featured
  );

  const handleAssets = () => {
    // getCollectionData(props.details.ref_id).then(
    //     (response: AxiosResponse) => {
    //         try {
    //             if (response.status === 200 || response.status === 201) {
    //                 const data = response.data as any;
    history.push({
      pathname: MY_VIDEO,
      state: props.details.ref_id,
    });
    //         }
    //     } catch (error) {
    //         handleUncaughtError(error);
    //     }
    // }
    // );
  };

  const handleActiveChange = () => {
    toggleActiveStatus(props.details.ref_id, setActiveChecked);
  };

  const handleFeaturedChange = () => {
    toggleFeaturedStatus(props.details.ref_id, setFeaturedChecked);
  };

  return (
    <div className="content-card">
      <div className="thumbnail-container">
        <img
          className="thumbnail"
          src={props.details.banners}
          alt="thumbnail"
          loading="lazy"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = THUMBNAIL_NOT_AVAILABLE;
          }}
        />
      </div>
      <div className="card-body">
        <h3
          className="card-body-title ellipsis overflow"
          title={props.details.title}
        >
          {props.details.title}
        </h3>
        <ReadMore>{props.details.description}</ReadMore>
        <p className="content-text">
          <strong>Type Of Content:</strong>{' '}
          {subType[props.details.sub_type].name}
        </p>
        <p className="content-text" title={props.details.genre}>
          <strong>Genre:</strong> {props.details.genre.substring(0, 20)}
          {props.details.genre.length > 21 ? '...' : ''}
        </p>
      </div>
      <div className="content-actions">
        <button
          className="btn btn-primary btn-block btn-sm"
          onClick={handleAssets}
        >
          View More
        </button>
        <div className="content-active">
          <p>Active</p>
          <Switch checked={isActiveChecked} onChange={handleActiveChange} />
        </div>
        <div className="content-active">
          <p>Featured</p>
          <Switch checked={isFeaturedChecked} onChange={handleFeaturedChange} />
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
