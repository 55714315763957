import React from 'react';

type Props = {
    fileUploadSteps: string;
    uploadingPercentage: number;
};

export const FileUploadProgress = (props: Props) => {
    if (props.fileUploadSteps !== 'upload-progress') {
        return null;
    }
    return (
        <div className="file-upload-section">
            <h3 className="upload-percent">{props.uploadingPercentage} %</h3>
            <div className="upload-progress-bar">
                <div className="upload-progress upload-progress-lower"></div>
                <div
                    className="upload-progress upload-progress-upper"
                    style={{ width: `${props.uploadingPercentage}%` }}
                ></div>
            </div>
            <h3>Please wait while your content is being uploaded.</h3>
        </div>
    );
};
