import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { CollectionData } from '../../../data';
import {
  addArtistMember,
  addCastInMovie,
  AddCastPayload,
  Artist,
  CastMember,
  castRole,
  fetchArtists,
} from '../data';
import { Field, Form, Formik } from 'formik';
import UploadProfilePic from './UploadProfilePic';
import { showAlert } from '../../../../../store';
import { CAST_MEMEBER_ADDED } from '../../../../../utils/app.constants';
import { Cast } from './Cast';
import './AddCasts.scss';

type Props = {
  videoData: {
    cast_members?: Array<CastMember>;
    collection?: CollectionData;
    directors?: Array<string>;
  };
  addedCastMember: CastMember[];
  setAddedCastMember: React.Dispatch<React.SetStateAction<CastMember[]>>;
  getListCollection: () => void;
};

const AddCast = (props: Props) => {
  const [searchArtist, setSearchArtists] = useState<string>('');
  const [isAddNewArtist, setAddNewArtist] = useState<boolean>(false);
  const [isUploadProfilePic, setUploadProfilePic] = useState<boolean>(false);
  const [artists, setArtists] = useState<any>();
  const handleArtistSearch = () => {
    fetchArtists(searchArtist, setArtists);
    if (artists && !artists.length) {
      setAddNewArtist(false);
      setUploadProfilePic(false);
    }
  };
  const [newArtist, setNewArtist] = useState<any>();
  const artistRef = useRef<HTMLDivElement>(null);

  const toggleNewArtists = () => {
    setAddNewArtist((prev) => !prev);
    if (artistRef && artistRef.current) {
      artistRef.current.scrollIntoView();
    }
  };

  const addArtist = (artist: Artist) => {
    const data: AddCastPayload = {
      name: artist.name,
      artist_ref_id: artist.ref_id,
      collection_ref_id: props.videoData?.collection?.ref_id
        ? props.videoData.collection.ref_id
        : '',
      role: 0,
    };
    if (props.videoData?.collection?.is_multipart) {
      data.season = props.videoData?.collection?.season_ref_id;
    }
    addCastInMovie(data).then((response: any) => {
      if (response.status === 201 || response.status === 200) {
        props.setAddedCastMember((prev) => {
          return [...prev, { ...response.data }];
        });
        showAlert({
          alertType: 'success',
          alertMessage: CAST_MEMEBER_ADDED,
          isAlertVisible: true,
        });
        void props.getListCollection();
      }
    });
  };

  const initialValues = {
    name: '',
    age: '',
    role: '',
    wikilink: '',
    avatar: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    age: Yup.number(),
    role: Yup.number(),
  });

  const handleOnEnter = (event: any) => {
    if (event.key === 'Enter' && !isAddNewArtist) {
      handleArtistSearch();
    }
  };

  return (
    <div className="add-artists">
      <div className="search-box">
        <input
          className="input-box"
          type="search"
          placeholder="Search here..."
          value={searchArtist}
          onChange={(event) => setSearchArtists(event.target.value)}
          onKeyDown={handleOnEnter}
        />
        {!isAddNewArtist && (
          <button className="rounded-border-btn" onClick={handleArtistSearch}>
            Search
          </button>
        )}
        {isAddNewArtist && (
          <button className="rounded-border-btn" onClick={toggleNewArtists}>
            Back
          </button>
        )}
      </div>
      <div className="search-result-container">
        <div className="search-result">
          <>
            {!isAddNewArtist && (
              <>
                {artists && (
                  <>
                    {artists.length !== 0 ? (
                      <div className="artist-list">
                        {artists.map((artist: Artist) => (
                          <Cast
                            key={artist.ref_id}
                            artist={artist}
                            addArtist={addArtist}
                            addedCastMember={props.addedCastMember}
                          />
                        ))}
                        {!isAddNewArtist && (
                          <div className="artists-bottom-sec">
                            Didn't get your cast, here you can{' '}
                            <button
                              className="btn btn-primary"
                              onClick={toggleNewArtists}
                            >
                              add new cast
                            </button>
                          </div>
                        )}
                        <div ref={artistRef}></div>
                      </div>
                    ) : (
                      <div className="no-artist">
                        <h3>
                          Not found{' '}
                          <button
                            className="btn btn-primary"
                            onClick={toggleNewArtists}
                          >
                            add new cast
                          </button>
                        </h3>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
          <div className="add-artist-form">
            {isAddNewArtist &&
              (!isUploadProfilePic ? (
                <div className="add-new-artists">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      const data = {
                        name: values.name,
                        age: Number(values.age),
                        wiki_link: values.wikilink,
                        avatar: values.avatar,
                        role: parseInt(values.role),
                      };
                      addArtistMember(
                        data,
                        props.videoData.collection,
                        setNewArtist,
                        setUploadProfilePic
                      );
                    }}
                  >
                    {({ errors, touched, handleSubmit }) => (
                      <Form
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit();
                          }
                        }}
                      >
                        <div className="form-group floating-input">
                          <Field
                            className="input-group"
                            name="name"
                            placeholder="Enter name..."
                          />
                          <label className="input-label" htmlFor="name">
                            Cast Name
                          </label>
                          {errors.name && touched.name && (
                            <p className="error-msg">{errors.name}</p>
                          )}
                        </div>
                        <div className="form-group floating-input">
                          <Field
                            className="input-group"
                            name="age"
                            placeholder="Enter age..."
                          />
                          <label className="input-label" htmlFor="age">
                            Age
                          </label>
                          {errors.age && touched.age && (
                            <p className="error-msg">{errors.age}</p>
                          )}
                        </div>
                        <div className="form-group floating-input">
                          <Field
                            name="role"
                            as="select"
                            className={`input-group ${
                              errors.role && touched.role && 'error'
                            }`}
                          >
                            <option value="" hidden>
                              Select Role
                            </option>
                            {castRole.map((item) => {
                              return (
                                <option key={item.name} value={item.value}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Field>
                          <label className="input-label" htmlFor="sub_type">
                            Role
                          </label>
                          {errors.role && touched.role && (
                            <span className="error-msg">{errors.role}</span>
                          )}
                        </div>
                        <div className="action-row">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Add
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <>
                  {newArtist && newArtist.ref_id && (
                    <UploadProfilePic
                      getListCollection={props.getListCollection}
                      ref_id={newArtist.ref_id}
                      setAddNewArtist={setAddNewArtist}
                      setUploadProfilePic={setUploadProfilePic}
                    />
                  )}
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCast;
