import { AlertType } from './store.model';
import { getLocalAccessToken } from '../../services/api';
import { InitialState } from './store.model';

export const intialAlertData: AlertType = {
  alertMessage: '',
  alertType: '',
  alertTime: 10000,
  isAlertVisible: false,
};

export const initialState: InitialState = {
  isAuthenticated: getLocalAccessToken() ? true : false,
  alertMessage: '',
  alertType: '',
  alertTime: 10000,
  isAlertVisible: false,
  isLoading: false,
  user: {
    associated_teams: null,
    email: '',
    accessToken: getLocalAccessToken(),
  },
  addMovie: {
    isUpdate: false,
    isMetaDataFormPopUp: false,
  },
};
export const SHOW_ALERT = 'SHOW_ALERT';
export const LOGIN = 'LOGIN';
export const ADD_MOVIE = 'ADD_MOVIE';
export const LOADING = 'LOADING';
export const HANDLE_UNAUTHORIZED = 'HANDLE_UNAUTHORIZED';
