import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Loader.scss';
import { store } from '../../store';

const Loader = () => {
    const { isLoading } = store.getState();

    return (
        <>
            {isLoading && (
                <div className="loader">
                    <Box>
                        <CircularProgress color="success" />
                    </Box>
                </div>
            )}
        </>
    );
};

export default Loader;
