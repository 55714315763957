import React, { useEffect, useState } from 'react';
import { CardData, CastMember, myVideoList } from '../data';
import { FormDataType } from '../../../../Dashboard/data/dashboard.model';
import { MasterUpload } from '../UploadPopUp/MasterUpload';
import UploadAsset from './UploadAsset';
import PreviewAsset from './PreviewAsset';
import SeasonPopUp from './SeasonPopUp';
import { AssetOptions } from './AssetOptions/AssetOptions';

type Props = {
  cardData?: CardData;
  videoData: {
    cast_members: Array<CastMember>;
    collection: FormDataType;
    directors: Array<string>;
  };
  setAddedCastMember: React.Dispatch<React.SetStateAction<CastMember[]>>;
  getListCollection: () => void;
};

const VideoAssets = (props: Props) => {
  const [isUploadPopup, setUploadPopup] = useState<boolean>(false);
  const [selectedCardData, setSelectedCardData] = useState<CardData>();
  const [activeAsset, setActiveAsset] = useState<string>('');
  const [isSeasonPopUp, setSeasonPopUp] = useState<boolean>(false);

  useEffect(() => {
    if (props?.videoData.collection.is_multipart) {
      setActiveAsset('seasons');
    } else {
      setActiveAsset('videos');
    }
  }, [props?.videoData.collection.is_multipart]);

  const getAssetInfo = React.useCallback(() => {
    const assetInfo = myVideoList?.filter(
      (item) => item.asset === activeAsset
    )[0];
    setSelectedCardData(assetInfo);
  }, [activeAsset]);

  useEffect(() => {
    void getAssetInfo();
  }, [getAssetInfo]);

  if (props.cardData && props.cardData?.assetType === 'movieCast') {
    return (
      <>
        {isUploadPopup && (
          <>
            <MasterUpload
              cardData={props.cardData}
              videoData={props.videoData}
              inputType="file"
              setUploadPopup={setUploadPopup}
              addedCastMember={props.videoData.cast_members}
              setAddedCastMember={props.setAddedCastMember}
              getListCollection={props.getListCollection}
              level="collection"
              language={props.videoData.collection.language}
            />
          </>
        )}
        <button
          className="btn btn-secondary w-100 mt16"
          onClick={() => setUploadPopup(true)}
        >
          Add Cast
        </button>
      </>
    );
  }

  return (
    <>
      {isSeasonPopUp && (
        <SeasonPopUp
          onClose={setSeasonPopUp}
          isUpdate={false}
          refId={props.videoData.collection.ref_id}
          getListCollection={props.getListCollection}
        />
      )}
      <AssetOptions
        is_multipart={props?.videoData.collection.is_multipart}
        setActiveAsset={setActiveAsset}
        activeAsset={activeAsset}
      />
      {isUploadPopup && (
        <>
          <MasterUpload
            cardData={selectedCardData}
            videoData={props.videoData}
            inputType="file"
            setUploadPopup={setUploadPopup}
            addedCastMember={props.videoData.cast_members}
            setAddedCastMember={props.setAddedCastMember}
            getListCollection={props.getListCollection}
            level="collection"
            language={props.videoData.collection.language}
          />
        </>
      )}
      <UploadAsset
        cardData={selectedCardData}
        setUploadPopup={setUploadPopup}
        setSeasonPopUp={setSeasonPopUp}
        collectionRefId={props?.videoData.collection.ref_id}
        seasons={props?.videoData.collection.seasons}
        getListCollection={props.getListCollection}
        language={props?.videoData.collection.language}
      />
      <PreviewAsset
        cardData={selectedCardData}
        activeAsset={activeAsset}
        videoData={props.videoData}
      />
    </>
  );
};

export default VideoAssets;
