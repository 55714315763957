import { Api } from '../../../services';
import { FormDataType } from './dashboard.model';
import { addOrUpdateMovie, showAlert } from '../../../store';
import {
  SUBMITTED_SUCCESSFULLY,
  UPDATED_SUCCESSFULLY,
} from '../../../utils/app.constants';
import { handleUncaughtError } from '../../../utils/utilityService';
import { AxiosResponse } from 'axios';
import { MY_VIDEO } from '../../../utils/routes.constants';

export const fetchListCollection = async (url: string) => {
  const response: any = await Api.get(url);
  if (response.status === 200 && response.data.success) {
    return response.data.data;
  }
};

export const fetchMoreContents = async (
  next: string,
  path: 'list_collections' | 'list_movies'
) => {
  const response: any = await Api.get(`${path}?cursor=` + next);
  if (response.status === 200 && response.data.success) {
    return response.data.data;
  }
};

export const addCollection = async ({
  method,
  data,
  history,
}: {
  method: string;
  data: FormDataType;
  history: any;
}) => {
  Api.post(method, data).then((response: any) => {
    if (response.status === 201 || response.status === 200) {
      showAlert({
        alertMessage: SUBMITTED_SUCCESSFULLY,
        alertType: 'success',
        isAlertVisible: true,
      });
      addOrUpdateMovie({
        isMetaDataFormPopUp: false,
        isUpdate: false,
      });
      history.push({
        pathname: MY_VIDEO,
        state: response.data.ref_id,
      });
    }
  });
};

export const updateCollection = async ({
  method,
  data,
}: {
  method: string;
  data: FormDataType;
}) => {
  Api.post(method, data).then((response: any) => {
    if (response.status === 201 || response.status === 200) {
      showAlert({
        alertMessage: UPDATED_SUCCESSFULLY,
        alertType: 'success',
        isAlertVisible: true,
      });
      return response.data.data;
    }
  });
};

export const toggleFeaturedStatus = (
  ref_id: string,
  setFeaturedChecked: React.Dispatch<React.SetStateAction<boolean | undefined>>
) => {
  Api.post('toggle_featured_status/', {
    ref_id: ref_id,
  }).then((response: AxiosResponse) => {
    try {
      if (response.status === 200 || response.status === 201) {
        showAlert({
          alertMessage: 'Featured status updated.',
          alertType: 'success',
          isAlertVisible: true,
        });
        const toggle = response as any;
        setFeaturedChecked(toggle.data.data['featured']);
      }
    } catch (error) {
      handleUncaughtError(error);
    }
  });
};

export const toggleActiveStatus = (
  ref_id: string,
  setActiveChecked: React.Dispatch<React.SetStateAction<boolean | undefined>>
) => {
  Api.post('toggle_active_status/', {
    ref_id: ref_id,
  }).then((response: AxiosResponse) => {
    // try {
    if (response.status === 200 || response.status === 201) {
      showAlert({
        alertMessage: 'Active status updated.',
        alertType: 'success',
        isAlertVisible: true,
      });
      const toggle = response as any;
      setActiveChecked(toggle.data.data['active']);
    }
    // } catch (error) {
    //     handleUncaughtError(error);
    // }
  });
};

export const getCollectionData = async (ref_id: string) => {
  const response = await Api.post('get_collection/', {
    id: ref_id,
  });
  return response;
};
