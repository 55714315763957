import React, { useState } from 'react';

type Props = {
    children: string;
};

const ReadMore = (props: Props) => {
    const text = props.children;
    const isLengthGreater = text.length > 150 ? true : false;
    const [isReadMore, setIsReadMore] = useState(isLengthGreater);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="card-body-desc">
            {text.length < 145 ? (
                <>{text}</>
            ) : (
                <>
                    {isReadMore ? text.slice(0, 145) : text}
                    <span onClick={toggleReadMore} className="read-or-hide">
                        {isReadMore ? '...read more' : ' show less'}
                    </span>
                </>
            )}
        </p>
    );
};

export default ReadMore;
