import { FormDataType, initialValues } from '../../../data';

// export const modifyData = (collection: FormDataType): FormDataType => {
//     let data = {} as any;
//     try {
//         if (collection) {
//             data = {
//                 ...initialValues,
//                 ...collection,
//             };
//             data.release_date = collection.release_date.split('T')[0];
//         }
//     } catch (error) {
//         console.error(error);
//     }
//     return data;
// };

export const trimLongString = (char: string, length?: number) => {
    const exactLength = length ? length + 1 : 15;
    if (char.length > exactLength) {
        const trimmedChar = char.substring(0, exactLength);
        return trimmedChar + '...';
    } else {
        return char;
    }
};
