import React from 'react';
import SubtitlesIcon from '@mui/icons-material/Subtitles';

type Props = {
  fileType?: string;
  url: string;
};

const RenderAssetFile: React.FC<Props> = ({ fileType, url }) => {
  switch (fileType) {
    case 'banners':
    case 'thumbnails':
      return <img src={url} alt={url}></img>;
    case 'videos':
    case 'trailers':
      return (
        <video width="320" height="240" controls>
          <source src={url} type="video/mp4" />
          <source src={url} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      );
    case 'subtitles':
      return <SubtitlesIcon fontSize="large" className="subtitle-file-icon" />;
    case 'audios':
      return (
        <audio controls>
          <source src={url} type="video/mp4" />
          <source src={url} type="video/ogg" />
          Your browser does not support the video tag.
        </audio>
      );
    default:
      return null;
  }
};

export default RenderAssetFile;
