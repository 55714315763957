import React from 'react';

interface Props {
  color: string;
  width: number;
  height: number;
}

const CardIC: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.7821 19.6599H9.93558V14.8041H48.7821V19.6599ZM48.7821 43.9389H9.93558V29.3715H48.7821V43.9389ZM48.7821 9.94824H9.93558C7.24061 9.94824 5.07977 12.1091 5.07977 14.8041V43.9389C5.07977 45.2267 5.59137 46.4618 6.50201 47.3725C7.41265 48.2831 8.64774 48.7947 9.93558 48.7947H48.7821C50.0699 48.7947 51.305 48.2831 52.2156 47.3725C53.1263 46.4618 53.6379 45.2267 53.6379 43.9389V14.8041C53.6379 12.1091 51.4528 9.94824 48.7821 9.94824Z"
        fill={color}
      />
    </svg>
  );
};

export default CardIC;
