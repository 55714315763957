import React, { Component, ErrorInfo, ReactNode } from "react";
import './ErrorBoundary.scss'

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  isVisible: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    isVisible: true,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true, isVisible: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error({type: "Uncaught error:", error, errorInfo});
  }

  closeErrorMessage = ()=>{
      this.setState({isVisible: false})
  }

  public render() {
    if (this.state.hasError) {
      return <>{
                this.state.isVisible &&
                <div className="error-boundary">
                    <div className="error-flex-item">
                        <div className="error-message">
                            <h3>Something went wrong!</h3>
                            <p>Sorry.. there was an error. Please try later or refresh the page.</p>
                        </div>
                        <div className="error-close">
                            <button onClick={this.closeErrorMessage}>Close</button>
                        </div>
                    </div>
                </div>
                }
            </>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;