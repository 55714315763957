// @flow
import * as React from 'react';
type Props = {
  activeAsset: string;
  is_multipart?: boolean;
  setActiveAsset: (value: React.SetStateAction<string>) => void;
};
export const AssetOptions: React.FC<Props> = ({
  activeAsset,
  is_multipart,
  setActiveAsset,
}) => {
  return (
    <div className="asset-options">
      {is_multipart ? (
        <button
          onClick={() => setActiveAsset('seasons')}
          className={`rounded-border-btn ${
            activeAsset === 'seasons' ? 'btn-primary' : ''
          }`}
        >
          Season
        </button>
      ) : (
        <button
          onClick={() => setActiveAsset('videos')}
          className={`rounded-border-btn ${
            activeAsset === 'videos' ? 'btn-primary' : ''
          }`}
        >
          Video
        </button>
      )}
      <button
        onClick={() => setActiveAsset('trailers')}
        className={`rounded-border-btn ${
          activeAsset === 'trailers' ? 'btn-primary' : ''
        }`}
      >
        Trailer
      </button>
      {!is_multipart && (
        <button
          onClick={() => setActiveAsset('subtitles')}
          className={`rounded-border-btn ${
            activeAsset === 'subtitles' ? 'btn-primary' : ''
          }`}
        >
          Sub Title
        </button>
      )}
      <button
        onClick={() => setActiveAsset('banners')}
        className={`rounded-border-btn ${
          activeAsset === 'banners' ? 'btn-primary' : ''
        }`}
      >
        Banner
      </button>
      <button
        onClick={() => setActiveAsset('thumbnails')}
        className={`rounded-border-btn ${
          activeAsset === 'thumbnails' ? 'btn-primary' : ''
        }`}
      >
        Thumbnail
      </button>
    </div>
  );
};
