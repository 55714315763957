import React from 'react';
import { Assets, FormDataType } from '../../../../data';
import { CardData, CastMember } from '../../data';
import Preview from './Preview';
import './PreviewAsset.scss';

type Props = {
  cardData?: CardData;
  videoData: {
    cast_members: Array<CastMember>;
    collection: FormDataType;
    directors: Array<string>;
  };
  activeAsset: string;
};

const PreviewAsset = ({ cardData, videoData, activeAsset }: Props) => {
  const assetsData = videoData?.collection.assets as any;
  if (
    assetsData &&
    assetsData[activeAsset] &&
    assetsData[activeAsset].length > 0
  ) {
    return (
      <>
        {assetsData[activeAsset]?.length &&
          assetsData[activeAsset].map((asset: Assets) => (
            <Preview key={asset?.id} asset={asset} cardData={cardData} />
          ))}
      </>
    );
  }

  return null;
};

export default PreviewAsset;
