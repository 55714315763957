import React, { useState } from 'react';
import { showAlert } from '../../../../../store';
import { FAILED_TO_UPLOAD } from '../../../../../utils/app.constants';
import {
  assetUploadStatus,
  getProfileUploadUrl,
  PostFormData,
  uploadFileToBucket,
} from '../data';
import { UploadToS3Bucket } from '../UploadPopUp/UploadToS3Bucket';
import './AddCasts.scss';

type Props = {
  ref_id?: string;
  setAddNewArtist: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadProfilePic: React.Dispatch<React.SetStateAction<boolean>>;
  getListCollection: () => void;
};

const UploadProfilePic = (props: Props) => {
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [preSignedUrl, setPreSignedUrl] = useState<PostFormData>();
  const [isUploading, setUploading] = useState<boolean>(false);
  const [assetUploadStatusData, setUploadAssetStatus] = useState<any>();
  const [uploadingPercentage, setUploadingPercentage] = useState<number>(0);
  const [previewUrl, setPreviewUrl] = useState<any>('');
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files && event.target.files[0];
      const reader = new FileReader();
      if (file?.type.includes('image')) {
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setPreviewUrl([reader.result]);
        };
      }
      setSelectedFile(file);
      getPresignedUrl();
    } catch (error) {
      console.error(error);
    }
  };
  const getPresignedUrl = () => {
    try {
      const data = {
        level: 'artist',
        ref_id: props.ref_id,
        asset_type: 'artist_avatar',
        meta: {},
      };
      if (data) {
        getProfileUploadUrl(data, setPreSignedUrl, setUploadAssetStatus);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadToS3Bucket = async () => {
    try {
      const formData = new FormData();
      if (preSignedUrl) {
        Object.entries(preSignedUrl.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
      }
      formData.append('file', selectedFile);
      if (preSignedUrl) {
        setUploading(true);
        uploadFileToBucket(preSignedUrl.url, formData, setUploadingPercentage)
          .then((response: any) => {
            setUploading(false);
            if (response.status === 204) {
              void props.getListCollection();
              showAlert({
                alertMessage: 'Profile uploaded successfully',
                alertType: 'success',
                isAlertVisible: true,
              });
              assetUploadStatus({
                id: assetUploadStatusData.id,
                name: assetUploadStatusData.name,
              });
              props.setAddNewArtist(false);
              props.setUploadProfilePic(false);
            } else {
              showAlert({
                alertMessage: FAILED_TO_UPLOAD,
                alertType: 'success',
                isAlertVisible: true,
              });
            }
          })
          .catch(() => {
            setUploading(false);
            showAlert({
              alertMessage: FAILED_TO_UPLOAD,
              alertType: 'error',
              isAlertVisible: true,
            });
          });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="form-group profile-pic">
      <input type="file" onChange={handleFileUpload} id="profile_pic" />
      <label htmlFor="profile_pic" className="btn btn-secondary">
        Choose file
      </label>
      <UploadToS3Bucket
        previewUrl={previewUrl}
        selectedFile={selectedFile}
        uploadToS3Bucket={uploadToS3Bucket}
        isUploading={isUploading}
        uploadingPercentage={uploadingPercentage}
      />
    </div>
  );
};

export default UploadProfilePic;
