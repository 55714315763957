import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import './TagCard.scss';
import { AdTag } from '../data';

type Props = {
  tag: AdTag;
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAd: React.Dispatch<React.SetStateAction<AdTag | null>>;
};

const TagCard: React.FC<Props> = ({ tag, setSelectedAd, setPopUp }) => {
  const updateTag = () => {
    setSelectedAd(tag);
    setPopUp(true);
  };
  return (
    <div className="tag" onClick={updateTag}>
      <div className="tag-info">
        <div className="tag-name">Tag Name: {tag.name}</div>
        <div className="tag-url">
          Tag URL:{' '}
          <a
            href={tag.url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {tag.url}
          </a>
        </div>
      </div>
      <div className="tag-icon">
        <LocalOfferIcon />
      </div>
    </div>
  );
};

export default TagCard;
