import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { sidebarList } from '../components/Sidebar/data/sidebar.constants';
import { Monitization } from '../components/VideoManager';
import '../Dashboard.scss';

const VideoUpload = () => {
  return (
    <div className="dashboard">
      <ErrorBoundary>
        <Header />
      </ErrorBoundary>
      <div className="dashboard-main">
        <ErrorBoundary>
          <Sidebar routes={sidebarList} />
        </ErrorBoundary>
        <ErrorBoundary>
          <Monitization />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default VideoUpload;
